import { Box, Grid, Hidden, ShiftTheme, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';

import AbnText from '../../../components/AbnText';

const useStyles = makeStyles((theme: ShiftTheme) => ({
  subLabel: {
    fontWeight: 500,
    marginRight: theme.spacing(1),
  },
  subBullet: {
    fontWeight: 500,
    color: theme.palette.common.orange,
    lineHeight: 1.2,
    margin: theme.spacing(0, 2),
  },
  details: {
    marginTop: theme.spacing(1),
  },
  editButton: {
    height: theme.spacing(1.5),
    fontSize: theme.spacing(1.5),
    marginLeft: theme.spacing(1),
    padding: theme.spacing(0),
  },
  mobileEditContainer: {
    marginLeft: theme.spacing(4),
  },
}));

type Props = {
  accountName: string;
  abn: string;
  bsb: string;
  bankAccountNumber: string;
  supplierGcAccountId?: string;
  isAto: boolean;
};

const SupplierProfile = (props: Props) => {
  const { accountName, abn, bsb, bankAccountNumber } = props;
  const classes = useStyles();

  return (
    <>
      <Grid>
        <Typography variant='h5' data-testid='uia-supplierAccountName'>
          {accountName}
        </Typography>
        <Hidden smUp>
          <Typography variant='body2'>
            <AbnText value={abn} />
          </Typography>
        </Hidden>
      </Grid>
      <Hidden smUp>
        <Grid container spacing={1} className={classes.details}>
          <Grid item xs={4}>
            <Typography variant='body2' className={classes.subLabel}>
              BSB
            </Typography>
            <Typography variant='body2'>{bsb}</Typography>
          </Grid>
          <Grid item xs={4}>
            <Typography variant='body2' className={classes.subLabel}>
              Account number
            </Typography>
            <Typography variant='body2'>{bankAccountNumber}</Typography>
          </Grid>
        </Grid>
      </Hidden>
      <Hidden xsDown>
        <Box display='flex' className={classes.details}>
          <Typography variant='body2' className={classes.subLabel}>
            ABN
          </Typography>
          <Typography variant='body2'>
            <AbnText value={abn} />
          </Typography>
          <Typography className={classes.subBullet}>&bull;</Typography>
          <Typography variant='body2' className={classes.subLabel}>
            BSB
          </Typography>
          <Typography variant='body2'>{bsb}</Typography>
          <Typography className={classes.subBullet}>&bull;</Typography>
          <Typography variant='body2' className={classes.subLabel}>
            Account number
          </Typography>
          <Typography variant='body2' data-testid={'uia-supplier-bank-account-number'}>
            {bankAccountNumber}
          </Typography>
        </Box>
      </Hidden>
    </>
  );
};

export default SupplierProfile;
