// @flow
import { handleActions } from 'redux-actions';
import { PARTITION, actionTypes, ACTION_PREFIX } from './constants';
import { getActionOptions } from '../../../lib/reduxActionsUtils';
import type { Invoice, FeesTermsAndPaymentTypes, ExtensionTermsAndFees } from './types';

export type ReduxState = {
  invoice?: Invoice,
  feesTermsAndPaymentTypes: FeesTermsAndPaymentTypes,
  extensionTermsAndFees: ExtensionTermsAndFees,
  installmentTermInWeeks: number,
  paymentDate: Date | null,
  autoBilling: boolean,
  adHocTerm: number,
};

const defaultFeesTermsAndPaymentTypes = {
  weekly: [],
  oneTime: [],
  balance: 0,
  nextPaymentAmount: 0,
  defaultTermsInDays: 0,
  availableDays: 0,
  paymentTypes: [],
};

const defaultExtensionTermsAndFees = {
  weeklyExtensionFees: [],
  oneTime: [],
  balance: 0,
  nextPaymentAmount: 0,
  availableDays: 0,
  canPayInFull: false,
  timeLeftToDueDate: 0,
  existingFee: 0,
  outstandingAmount: 0,
  totalNoOfInstallments: 0,
  weeklyRepayment: 0,
};

const initialState: ReduxState = {
  feesTermsAndPaymentTypes: defaultFeesTermsAndPaymentTypes,
  extensionTermsAndFees: defaultExtensionTermsAndFees,
  invoice: {},
  paymentDate: null,
  installmentTermInWeeks: -1,
  autoBilling: false,
  adHocTerm: 0,
};

const reducer = handleActions<ReduxState>(
  {
    [actionTypes.GET_INVOICE_FULFILLED]: (state, { payload }) => ({
      ...state,
      invoice: payload.data,
    }),
    [actionTypes.GET_TERMS_FEES_AND_PAYMENT_TYPES_FULFILLED]: (state, { payload }) => ({
      ...state,
      feesTermsAndPaymentTypes: payload.data,
    }),
    [actionTypes.GET_TRANSACTION_FEES_FULFILLED]: (state, { payload }) => ({
      ...state,
      feesTermsAndPaymentTypes: payload.data,
    }),
    [actionTypes.GET_EXTENSION_TERMS_AND_FEES_FULFILLED]: (state, { payload }) => ({
      ...state,
      extensionTermsAndFees: payload.data,
    }),
    [actionTypes.SET_PAYMENT_DATE]: (state, { payload }) => ({
      ...state,
      paymentDate: payload,
    }),
    [actionTypes.SET_INSTALLMENT_TERM_IN_WEEKS]: (state, { payload }) => ({
      ...state,
      installmentTermInWeeks: payload,
    }),
    [actionTypes.SET_INVOICE_DATA]: (state, { payload }) => ({
      ...state,
      invoice: payload,
    }),
    [actionTypes.ADD_INVOICE_FULFILLED]: (state) => ({
      ...state,
      feesTermsAndPaymentTypes: defaultFeesTermsAndPaymentTypes,
      invoice: {},
      paymentDate: null,
      installmentTermInWeeks: -1,
    }),
  },
  initialState,
  getActionOptions(ACTION_PREFIX)
);

export default {
  [PARTITION]: reducer,
};
