import { Grid } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { Box, Card, PictogramIcon, Stack, Text } from '@shiftfinancial/design-system';

const useStyles = makeStyles((theme) => ({
  mobileCard: {
    borderTop: 'none',
  },
  mobileContainer: {
    padding: theme.spacing(2),
    whiteSpace: 'normal',
  },
  mobileFieldsContainer: { margin: theme.spacing(15, 0, 30, 0) },
  topSpace: {
    marginTop: theme.spacing(2),
  },
  doubleTopSpace: {
    marginTop: theme.spacing(4),
  },
}));

export type GridEmptyStateProps = {
  title: string;
  content: string;

  icon: JSX.Element;
  isMobile: boolean;
};

export const GridEmptyState = (props: GridEmptyStateProps) => {
  const { title, content, icon, isMobile } = props;
  const classes = useStyles();

  const gridEmptyStateDesktop = () => {
    return (
      <>
        <Box sx={{ py: { xs: 'x30', sm: 'x40' } }}>
          <Stack orientation={'vertical'}>
            <PictogramIcon backdrop='primary' size='xlarge' backdropSize='xlarge'>
              {icon}
            </PictogramIcon>
            <Text fontSize='xlarge' fontWeight='medium' className={classes.topSpace}>
              {title}
            </Text>
            <Text fontSize='standard'>{content}</Text>
          </Stack>
        </Box>
      </>
    );
  };

  const gridEmptyStateMobile = () => {
    return (
      <Card variant='elevated' className={classes.mobileCard}>
        <Grid data-testid='uia-scheduled-invoices-mobile' className={classes.mobileContainer}>
          <Grid style={{ margin: '120px 0 240px 0' }}>
            <Grid item xs={12}>
              <PictogramIcon backdrop='primary' size='xlarge' backdropSize='xlarge'>
                {icon}
              </PictogramIcon>
            </Grid>
            <Grid item xs={12} className={classes.doubleTopSpace}>
              <Text fontSize='xlarge' fontWeight='medium'>
                {title}
              </Text>
            </Grid>
            <Grid item xs={12} className={classes.topSpace}>
              <Text fontSize='standard'>{content}</Text>
            </Grid>
          </Grid>
        </Grid>
      </Card>
    );
  };

  return isMobile ? gridEmptyStateMobile() : gridEmptyStateDesktop();
};
