// @flow
import { createActions } from 'redux-actions';
import type { Dispatch } from 'redux';

import { ACTION_PREFIX, actionTypes } from './constants';
import { getActionOptions } from '../../../lib/reduxActionsUtils';
import shiftPaymentsApi from '../../../api/shiftPaymentsApi';

export const {
  getAccountBalancePending,
  getAccountBalanceFulfilled,
  getAccountBalanceRejected,
  addInvoicePending,
  addInvoiceFulfilled,
  getSupplierBankAccountPending,
  getSupplierBankAccountFulfilled,
  getSupplierBankAccountRejected,
  checkBsbPending,
  checkBsbFulfilled,
  checkBsbRejected,
  getSupplierPending,
  getSupplierFulfilled,
  getSupplierRejected,
  fileDeletePending,
  fileDeleteFulfilled,
  fileDeleteRejected,
  fileUploadPending,
  fileUploadFulfilled,
  fileUploadRejected,
  setAllowJoinBrokerNetwork,
} = createActions(
  actionTypes.GET_ACCOUNT_BALANCE_PENDING,
  actionTypes.GET_ACCOUNT_BALANCE_FULFILLED,
  actionTypes.GET_ACCOUNT_BALANCE_REJECTED,
  actionTypes.ADD_INVOICE_PENDING,
  actionTypes.ADD_INVOICE_FULFILLED,
  actionTypes.GET_SUPPLIER_BANK_ACCOUNT_PENDING,
  actionTypes.GET_SUPPLIER_BANK_ACCOUNT_FULFILLED,
  actionTypes.GET_SUPPLIER_BANK_ACCOUNT_REJECTED,
  actionTypes.CHECK_BSB_PENDING,
  actionTypes.CHECK_BSB_FULFILLED,
  actionTypes.CHECK_BSB_REJECTED,
  actionTypes.GET_SUPPLIER_PENDING,
  actionTypes.GET_SUPPLIER_FULFILLED,
  actionTypes.GET_SUPPLIER_REJECTED,
  actionTypes.FILE_DELETE_PENDING,
  actionTypes.FILE_DELETE_FULFILLED,
  actionTypes.FILE_DELETE_REJECTED,
  actionTypes.FILE_UPLOAD_PENDING,
  actionTypes.FILE_UPLOAD_FULFILLED,
  actionTypes.FILE_UPLOAD_REJECTED,
  actionTypes.SET_ALLOW_JOIN_BROKER_NETWORK,
  getActionOptions(ACTION_PREFIX)
);

const getAccountBalance = (gcAccountId: string) => (dispatch: Dispatch<any>) => {
  dispatch(getAccountBalancePending());

  return shiftPaymentsApi
    .getBalanceSummary(gcAccountId)
    .then((response) => {
      return dispatch(getAccountBalanceFulfilled(response));
    })
    .catch((error) => {
      return dispatch(getAccountBalanceRejected(error));
    });
};

const addInvoice =
  (
    invoiceAmount: number,
    invoiceNumber: String,
    invoiceFiles: Array<any>,
    invoiceDescription: string,
    activeGcAccountId?: string,
    supplierGcAccountId: ?string,
    supplierAbn: string,
    supplierAccountName: string,
    supplierBsb: string,
    supplierBankAccountNumber: string,
    fileGroupId: string,
    created: Date,
    isNewSupplier: boolean,
    isDirector: boolean,
    disbursalMethod: string,
    disburseDate: string,
  ) =>
  (dispatch: Dispatch<any>) => {
    dispatch(addInvoicePending());
    const payload = {
      invoiceAmount,
      invoiceNumber,
      invoiceFiles,
      invoiceDescription,
      activeGcAccountId,
      supplierGcAccountId,
      supplierAccountName,
      supplierAbn,
      supplierBsb,
      supplierBankAccountNumber,
      supplierContactId: '',
      fileGroupId,
      created,
      isNewSupplier,
      isDirector,
      disbursalMethod,
      disburseDate,
    };
    return Promise.resolve(dispatch(addInvoiceFulfilled(payload)));
  };

const getSupplierBankAccount = (buyerGcAccountId: string, supplierGcAccountId: string) => (dispatch: Dispatch<any>) => {
  dispatch(getSupplierBankAccountPending());

  return shiftPaymentsApi
    .getSupplierBankAccount(buyerGcAccountId, supplierGcAccountId)
    .then((response) => {
      return dispatch(getSupplierBankAccountFulfilled(response));
    })
    .catch((error) => {
      return dispatch(getSupplierBankAccountRejected(error));
    });
};

const checkValidBsb = (bsbNumber: string) => (dispatch: Dispatch<any>) => {
  dispatch(checkBsbPending());

  return shiftPaymentsApi
    .getBankNameByBsbNumber(bsbNumber)
    .then((response) => {
      dispatch(checkBsbFulfilled());
      const { status } = response;
      return status === 200;
    })
    .catch(() => {
      dispatch(checkBsbRejected());
      return false;
    });
};

const getSupplier = (buyerGcAccountId: string, supplierGcAccountId: string) => (dispatch: Dispatch<any>) => {
  dispatch(getSupplierPending());

  return shiftPaymentsApi
    .getSuppliers(buyerGcAccountId, supplierGcAccountId)
    .then((response) => {
      return dispatch(getSupplierFulfilled(response));
    })
    .catch((error) => {
      return dispatch(getSupplierRejected(error));
    });
};

const deleteFiles = (gcAccountId: string, fileGroupId: string, files: Array<any>) => (dispatch: Dispatch<any>) => {
  dispatch(fileDeletePending());

  if (files && files.length) {
    const promises = files.map((file) => {
      return shiftPaymentsApi.removeInvoiceFile(gcAccountId, fileGroupId, file.name);
    });

    return (
      Promise.all(promises)
        // eslint-disable-next-line no-unused-vars
        .then((response) => dispatch(fileDeleteFulfilled(response)))
        .catch((error) => dispatch(fileDeleteRejected(error)))
    );
  }

  return Promise.resolve();
};

const uploadFile = (gcAccountId: string, fileGroupId: string, file: any) => (dispatch: Dispatch<any>) => {
  dispatch(fileUploadPending());

  return shiftPaymentsApi
    .uploadInvoiceFile(gcAccountId, fileGroupId, file)
    .then((response) => dispatch(fileUploadFulfilled(response)))
    .catch((error) => dispatch(fileUploadRejected(error)));
};

const deleteInvoiceFile = (gcAccountId: string, fileGroupId: string, file: any) => (dispatch: Dispatch<any>) => {
  dispatch(fileDeletePending());

  return shiftPaymentsApi
    .removeInvoiceFile(gcAccountId, fileGroupId, file.name)
    .then((response) => dispatch(fileDeleteFulfilled(response)))
    .catch((error) => dispatch(fileDeleteRejected(error)));
};

export const allowJoinBrokerNetwork = (isAllowed: boolean) => (dispatch: Dispatch<any>) => {
  dispatch(setAllowJoinBrokerNetwork(isAllowed));
};

export default {
  getAccountBalance,
  addInvoice,
  getSupplierBankAccount,
  checkValidBsb,
  getSupplier,
  deleteFiles,
  uploadFile,
  deleteInvoiceFile,
  allowJoinBrokerNetwork,
};
