import { makeStyles } from '@material-ui/core/styles';
import { Grid } from '@material-ui/core';
import moment from 'moment';
import React from 'react';
import { useDispatch } from 'react-redux';

import type { OrderBy } from '../../../api/shiftPaymentsApi';
import RemoteDataGrid from '../../../components/RemoteDataGrid';
import DollarsAndCentsText from '../../../components/DollarsAndCentsText';
import { Permission, STANDARD_DATE_FORMAT } from '../../../constants';
import { Can } from '../../../lib/ability';
import { enrichColumnsWithBodyRenders, getColumnIndex } from '../../../lib/dataGridUtils';
import actions from './actions';
import { Button, Text, Icon } from '@shiftfinancial/design-system';
import { GridEmptyState } from 'src/components/GridEmptyState/GridEmptyState';
import { ScheduleIcon } from '@shiftfinancial/react-icons/pictograms';
import { OutlinedCloseIcon } from '@shiftfinancial/react-icons/system';
import { isMobileResolution } from '../../../lib/materialUiUtils';
import withWidth from '@material-ui/core/withWidth';

const columnProps = {
  GC_ACCOUNT_ID: 'gcAccountId',
  DISBURSAL_DATE: 'disbursalDate',
  SUPPLIER_ACCOUNT_NAME: 'supplierAccountName',
  INVOICE_AMOUNT: 'invoiceAmount',
  INVOICE_REFERENCE: 'invoiceReference',
  FIRST_REPAYMENT_DATE: 'firstRepaymentDate',
  FIRST_REPAYMENT_AMOUNT: 'firstRepaymentAmount',
  IS_ELIGIBLE_TO_CANCEL: 'isEligibleToCancel',
};

const columnPropsLabels = {
  DISBURSAL_DATE_LABEL: 'Scheduled for',
  INVOICE_AMOUNT_LABEL: 'Invoice amount',
  INVOICE_REFERENCE_LABEL: 'invoice ref',
  FIRST_REPAYMENT_DATE_LABEL: 'First repayment',
  FIRST_REPAYMENT_AMOUNT_LABEL: 'Repayment amount',
};

const useStyles = makeStyles((theme) => ({
  container: {
    [theme.breakpoints.down('xs')]: {
      margin: theme.spacing(0, 1, 1, 1),
      borderTop: 'none',
      '& #uia-mobileRow-0': {
        borderBottom: `1px solid ${theme.palette.secondary.solitude}`,
        borderTop: 'none !important',
      },
    },
  },
  companyName: {
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    lineHeight: '20px',
    maxHeight: theme.spacing(7),
    display: '-webkit-box',
    '-webkit-line-clamp': 2,
    '-webkit-box-orient': 'vertical',
  },
  cancelLink: {
    color: '#D34343',
    textAlign: 'end',
    height: 'auto',
    lineHeight: 'normal',
    [theme.breakpoints.up('sm')]: {
      fontSize: theme.spacing(1.5),
    },
    [theme.breakpoints.down('xs')]: {
      paddingRight: 0,
      marginTop: theme.spacing(1),
      marginBottom: theme.spacing(0.5),
    },
  },
  mobileSupplierName: { marginTop: theme.spacing(0.5) },
  mobileValue: { textAlign: 'end', marginTop: theme.spacing(1), paddingRight: theme.spacing(2) },
  mobileLabel: { marginTop: theme.spacing(1) },
}));

let columns = [
  {
    name: columnProps.GC_ACCOUNT_ID,
    label: ' ',
    options: {
      display: false,
    },
  },
  {
    name: columnProps.DISBURSAL_DATE,
    label: 'Scheduled for',
  },
  {
    name: columnProps.SUPPLIER_ACCOUNT_NAME,
    label: 'Supplier Name',
    options: {
      setCellProps: () => {
        return {
          style: {
            minWidth: '280px',
          },
        };
      },
    },
  },
  {
    name: columnProps.INVOICE_AMOUNT,
    label: 'Invoice amount',
    options: {
      sort: false,
      setCellHeaderProps: () => {
        return { align: 'right' };
      },
      setCellProps: () => {
        return {
          align: 'right',
        };
      },
    },
  },
  {
    name: columnProps.INVOICE_REFERENCE,
    label: 'Invoice Ref',
  },
  {
    name: columnProps.FIRST_REPAYMENT_DATE,
    label: 'First repayment',
  },
  {
    name: columnProps.FIRST_REPAYMENT_AMOUNT,
    label: 'Repayment amount',
    options: {
      sort: false,
      setCellHeaderProps: () => {
        return { align: 'right' };
      },
      setCellProps: () => {
        return {
          align: 'right',
        };
      },
    },
  },
  {
    name: columnProps.IS_ELIGIBLE_TO_CANCEL,
    label: ' ',
    options: {
      sort: false,
    },
  },
];

const disbursalDateIndex = getColumnIndex(columns, columnProps.DISBURSAL_DATE);
const supplierAccountNameIndex = getColumnIndex(columns, columnProps.SUPPLIER_ACCOUNT_NAME);
const invoiceAmountIndex = getColumnIndex(columns, columnProps.INVOICE_AMOUNT);
const invoiceReferenceIndex = getColumnIndex(columns, columnProps.INVOICE_REFERENCE);
const firstRepaymentDateIndex = getColumnIndex(columns, columnProps.FIRST_REPAYMENT_DATE);
const firstRepaymentAmountIndex = getColumnIndex(columns, columnProps.FIRST_REPAYMENT_AMOUNT);
const isEligibleToCancelIndex = getColumnIndex(columns, columnProps.IS_ELIGIBLE_TO_CANCEL);

type Props = {
  gcAccountId: string,
  width: string,
};

const ScheduledInvoices = (props: Props) => {
  const { gcAccountId, width } = props;
  const classes = useStyles();
  const dispatch = useDispatch();
  const isMobile = isMobileResolution(width);

  const getScheduledInvoicesData = async (skip: number, limit: number, orderBy: Array<OrderBy> | null) => {
    return dispatch(actions.getScheduledInvoices(gcAccountId, skip, limit, orderBy));
  };

  const renderCancel = (isEligibleToCancel, isMobile) => {
    return isEligibleToCancel ? (
      <Can I={Permission.Actions.Create} a={Permission.Buyer.Invoice}>
        <Button
          appearance='textdanger'
          className={classes.cancelLink}
          startIcon={
            !!isMobile && (
              <Icon color={'danger'}>
                <OutlinedCloseIcon />
              </Icon>
            )
          }
          size='small'
          onClick={() => {}}
          data-testid='uia-scheduled-cancel-button'
        >
          Cancel
        </Button>
      </Can>
    ) : null;
  };

  const mobileRowRender = (rowData) => {
    const disbursalDate = rowData[disbursalDateIndex];
    const firstRepaymentDate = rowData[firstRepaymentDateIndex];
    return (
      <>
        <Grid container data-testid='uia-scheduled-invoices-mobile'>
          <Grid container>
            <Grid item xs={12}>
              <Text className={classes.mobileColumnLabel} color={'secondary'}>{`${columnPropsLabels.DISBURSAL_DATE_LABEL} ${addDashIfBlank(
                disbursalDate,
                moment(disbursalDate).format(STANDARD_DATE_FORMAT)
              )}`}</Text>
            </Grid>
          </Grid>
          <Grid item xs={12} className={classes.mobileSupplierName}>
            <Text fontSize={'standard'} fontWeight={'medium'} gap={'standard'}>
              {rowData[supplierAccountNameIndex]}
            </Text>
          </Grid>
          <Grid container xs={12}>
            <Grid item xs={6} className={classes.mobileLabel}>
              <Text color={'secondary'}>{columnPropsLabels.INVOICE_AMOUNT_LABEL}</Text>
            </Grid>
            <Grid item xs={6} className={classes.mobileValue}>
              <Text fontWeight={'medium'} fontSize={'standard'}>
                <DollarsAndCentsText amount={rowData[invoiceAmountIndex]} />
              </Text>
            </Grid>
          </Grid>
          <Grid container xs={12}>
            <Grid item xs={6} className={classes.mobileLabel}>
              <Text color={'secondary'}>{columnPropsLabels.INVOICE_REFERENCE_LABEL}</Text>
            </Grid>
            <Grid item xs={6} className={classes.mobileValue}>
              <Text fontWeight={'medium'} fontSize={'standard'}>
                {rowData[invoiceReferenceIndex]}
              </Text>
            </Grid>
          </Grid>
          <Grid container xs={12}>
            <Grid item xs={6} className={classes.mobileLabel}>
              <Text color={'secondary'}>{columnPropsLabels.FIRST_REPAYMENT_DATE_LABEL}</Text>
            </Grid>
            <Grid item xs={6} className={classes.mobileValue}>
              <Text fontWeight={'medium'} fontSize={'standard'}>{`${addDashIfBlank(
                firstRepaymentDate,
                moment(firstRepaymentDate).format(STANDARD_DATE_FORMAT)
              )}`}</Text>
            </Grid>
          </Grid>
          <Grid container xs={12}>
            <Grid item xs={6} className={classes.mobileLabel}>
              <Text color={'secondary'}>{columnPropsLabels.FIRST_REPAYMENT_AMOUNT_LABEL}</Text>
            </Grid>
            <Grid item xs={6} className={classes.mobileValue}>
              <Text fontWeight={'medium'} fontSize={'standard'}>
                <DollarsAndCentsText amount={rowData[firstRepaymentAmountIndex]} />
              </Text>
            </Grid>
          </Grid>
          <Grid className={classes.cancelLink} xs={12}>
            {renderCancel(rowData[isEligibleToCancelIndex], true)}
          </Grid>
        </Grid>
      </>
    );
  };

  const addDashIfBlank = (value: string | number, component: string | JSX.Element) => (value ? component : '-');

  const desktopColumnCustomBodyRenders = {
    disbursalDate: (value: string) => <Text> {addDashIfBlank(value, moment(value).format(STANDARD_DATE_FORMAT))}</Text>,
    supplierAccountName: (value: string) => <Text className={classes.companyName}>{value}</Text>,
    invoiceAmount: (value: number) => <Text>{addDashIfBlank(value, <DollarsAndCentsText amount={value} />)}</Text>,
    firstRepaymentDate: (value: string) => <Text> {addDashIfBlank(value, moment(value).format(STANDARD_DATE_FORMAT))}</Text>,
    firstRepaymentAmount: (value: number) => <Text>{addDashIfBlank(value, <DollarsAndCentsText amount={value} />)}</Text>,
    isEligibleToCancel: (value: boolean) => renderCancel(value, false),
  };

  return (
    <div className={classes.container}>
      <RemoteDataGrid
        getData={getScheduledInvoicesData}
        columns={enrichColumnsWithBodyRenders(columns, desktopColumnCustomBodyRenders)}
        mobileRowRender={mobileRowRender}
        data-testid='uia-dataGrid'
        options={{
          emptyState: (
            <GridEmptyState
              title='No scheduled supplier payments'
              content='Invoices that are scheduled to be paid to the supplier at a later date will appear here.'
              icon={<ScheduleIcon />}
              isMobile={isMobile}
            />
          ),
        }}
      />
    </div>
  );
};

export default withWidth()(ScheduledInvoices);
