export const ACTION_PREFIX = 'INVOICE';
export const PARTITION = 'invoice';

export const actionTypes = {
  GET_INVOICE_PENDING: 'GET_INVOICE_PENDING',
  GET_INVOICE_FULFILLED: 'GET_INVOICE_FULFILLED',
  GET_INVOICE_REJECTED: 'GET_INVOICE_REJECTED',
  GET_TERMS_FEES_AND_PAYMENT_TYPES_PENDING: 'GET_TERMS_FEES_AND_PAYMENT_TYPES_PENDING',
  GET_TERMS_FEES_AND_PAYMENT_TYPES_FULFILLED: 'GET_TERMS_FEES_AND_PAYMENT_TYPES_FULFILLED',
  GET_TERMS_FEES_AND_PAYMENT_TYPES_REJECTED: 'GET_TERMS_FEES_AND_PAYMENT_TYPES_REJECTED',
  GET_TRANSACTION_FEES_PENDING: 'GET_TRANSACTION_FEES_PENDING',
  GET_TRANSACTION_FEES_FULFILLED: 'GET_TRANSACTION_FEES_FULFILLED',
  GET_TRANSACTION_FEES_REJECTED: 'GET_TRANSACTION_FEES_REJECTED',
  GET_EXTENSION_TERMS_AND_FEES_PENDING: 'GET_EXTENSION_TERMS_AND_FEES_PENDING',
  GET_EXTENSION_TERMS_AND_FEES_FULFILLED: 'GET_EXTENSION_TERMS_AND_FEES_FULFILLED',
  GET_EXTENSION_TERMS_AND_FEES_REJECTED: 'GET_EXTENSION_TERMS_AND_FEES_REJECTED',
  CONFIRM_INVOICE_PENDING: 'CONFIRM_INVOICE_PENDING',
  CONFIRM_INVOICE_FULFILLED: 'CONFIRM_INVOICE_FULFILLED',
  CONFIRM_INVOICE_REJECTED: 'CONFIRM_INVOICE_REJECTED',
  DECLINE_INVOICE_PENDING: 'DECLINE_INVOICE_PENDING',
  DECLINE_INVOICE_FULFILLED: 'DECLINE_INVOICE_FULFILLED',
  DECLINE_INVOICE_REJECTED: 'DECLINE_INVOICE_REJECTED',
  ADD_INVOICE_PENDING: 'ADD_INVOICE_PENDING',
  ADD_INVOICE_FULFILLED: 'ADD_INVOICE_FULFILLED',
  ADD_INVOICE_REJECTED: 'ADD_INVOICE_REJECTED',
  GET_SUPPLIER_BANK_ACCOUNT_PENDING: 'GET_SUPPLIER_BANK_ACCOUNT_PENDING',
  GET_SUPPLIER_BANK_ACCOUNT_FULFILLED: 'GET_SUPPLIER_BANK_ACCOUNT_FULFILLED',
  GET_SUPPLIER_BANK_ACCOUNT_REJECTED: 'GET_SUPPLIER_BANK_ACCOUNT_REJECTED',
  SET_TRANSACTION_FEE: 'SET_TRANSACTION_FEE',
  SET_PAYMENT_DATE: 'SET_PAYMENT_DATE',
  SET_INSTALLMENT_TERM_IN_WEEKS: 'SET_INSTALLMENT_TERM_IN_WEEKS',
  GET_INVOICE_ATTACHMENT_PENDING: 'GET_INVOICE_ATTACHMENT_PENDING',
  GET_INVOICE_ATTACHMENT_FULFILLED: 'GET_INVOICE_ATTACHMENT_FULFILLED',
  GET_INVOICE_ATTACHMENT_REJECTED: 'GET_INVOICE_ATTACHMENT_REJECTED',
  SET_SUPPLIER_AUTHORIZATION_PENDING: 'SET_SUPPLIER_AUTHORIZATION_PENDING',
  SET_SUPPLIER_AUTHORIZATION_FULFILLED: 'SET_SUPPLIER_AUTHORIZATION_FULFILLED',
  SET_SUPPLIER_AUTHORIZATION_REJECTED: 'SET_SUPPLIER_AUTHORIZATION_REJECTED',
  SET_INVOICE_DATA: 'SET_INVOICE_DATA',
  GET_STAFF_PAGE_VISIBILITY_PENDING: 'GET_STAFF_PAGE_VISIBILITY_PENDING',
  GET_STAFF_PAGE_VISIBILITY_FULFILLED: 'GET_STAFF_PAGE_VISIBILITY_FULFILLED',
  GET_STAFF_PAGE_VISIBILITY_REJECTED: 'GET_STAFF_PAGE_VISIBILITY_REJECTED',
  ADD_SUPPLIER_PENDING: 'ADD_SUPPLIER_PENDING',
  ADD_SUPPLIER_FULFILLED: 'ADD_SUPPLIER_FULFILLED',
  ADD_SUPPLIER_REJECTED: 'ADD_SUPPLIER_REJECTED',
  EDIT_SUPPLIER_FULFILLED: 'EDIT_SUPPLIER_FULFILLED',
  EDIT_SUPPLIER_REJECTED: 'EDIT_SUPPLIER_REJECTED',
};
