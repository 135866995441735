import { createActions } from 'redux-actions';
import { getActionOptions } from '../../../lib/reduxActionsUtils';
import shiftPaymentsApi from '../../../api/shiftPaymentsApi';
import type { OrderBy } from '../../../api/shiftPaymentsApi';
import { type Dispatch } from 'redux';

const ACTION_PREFIX = 'SCHEDULED_INVOICES';

const actionTypes = {
  GET_SCHEDULED_INVOICES_PENDING: 'GET_SCHEDULED_INVOICES_PENDING',
  GET_SCHEDULED_INVOICES_FULFILLED: 'GET_SCHEDULED_INVOICES_FULFILLED',
  GET_SCHEDULED_INVOICES_REJECTED: 'GET_SCHEDULED_INVOICES_REJECTED',
};

const { getScheduledInvoicesPending, getScheduledInvoicesFulfilled, getScheduledInvoicesRejected } = createActions(
  actionTypes.GET_SCHEDULED_INVOICES_PENDING,
  actionTypes.GET_SCHEDULED_INVOICES_FULFILLED,
  actionTypes.GET_SCHEDULED_INVOICES_REJECTED,
  getActionOptions(ACTION_PREFIX)
);

const getScheduledInvoices = (gcAccountId: string, skip: number, limit: number, orderBy: OrderBy[] | null) => (dispatch: Dispatch<any>) => {
  dispatch(getScheduledInvoicesPending());

  return shiftPaymentsApi
    .getScheduledInvoices(gcAccountId, skip, limit, orderBy)
    .then((response) => {
      return dispatch(getScheduledInvoicesFulfilled(response));
    })
    .catch((error) => {
      return dispatch(getScheduledInvoicesRejected(error));
    });
};

export default {
  getScheduledInvoices,
};
