// @flow
import React, { useEffect, useCallback } from 'react';
import SearchIcon from '@material-ui/icons/Search';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import withWidth from '@material-ui/core/withWidth';
import { Typography, Box } from '@material-ui/core';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import Paper from '@material-ui/core/Paper';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';

import AutoSuggestInput from './AutoSuggestInput';
import AbnText from './AbnText';
import { isMobileResolution } from '../lib/materialUiUtils';
import { formatAbnOrAcn } from '../lib/formatter';

const useStyles = makeStyles((theme) => ({
  searchIcon: {
    color: theme.palette.primary.main,
    marginRight: theme.spacing(1),
  },
  closeIcon: {
    color: theme.palette.grey.light,
    marginLeft: theme.spacing(1),
    cursor: 'pointer',
  },
  mobileDetailContainer: {
    marginTop: theme.spacing(2),
    padding: theme.spacing(2),
    border: `1px solid ${theme.palette.grey['300']}`,
    borderRadius: 4,
    backgroundColor: theme.palette.common.white,
  },
  mobileDetailValue: {
    marginBottom: theme.spacing(2),
  },
  desktopDetailContainer: {
    width: '100%',
    marginTop: theme.spacing(2),
    overflowX: 'auto',
    borderRadius: 4,
    boxShadow: 'none',
    border: `1px solid ${theme.palette.grey['300']}`,
  },
}));

const TableHeadRow = withStyles((theme) => ({
  head: {
    backgroundColor: theme.palette.common.blueGreen,
  },
}))(TableRow);

const TableHeadCell = withStyles((theme) => ({
  head: {
    color: theme.palette.common.white,
    fontSize: 14,
  },
}))(TableCell);

// eslint-disable-next-line no-unused-vars
const TableBodyRow = withStyles(() => ({
  root: {
    '&:last-child': {
      '& td': {
        borderBottom: 0,
      },
    },
  },
}))(TableRow);

// eslint-disable-next-line no-unused-vars
const TableBodyCell = withStyles(() => ({
  body: {
    fontSize: 14,
  },
}))(TableCell);

type Props = {
  width: string,
  getSuggestions: (keyword: string) => Promise<any>,
  onSuggestionSelected: (event: SyntheticKeyboardEvent<any>, data: any) => void,
  delay: number,
  onKeyPress?: (event: SyntheticKeyboardEvent<any>) => void,
  onKeyUp?: (event: SyntheticKeyboardEvent<any>) => void,
  autoFocus: boolean,
  suggestions: { rows: Array<any>, search: string },
  fieldValue: string | Object,
  searchMode: 'AbnSearch' | 'ShiftSearch',
  showGrid: boolean,
  defaultSelectedAbn: string,
};

export type EntitySuggestionItem = {
  id: string,
  entityName: string,
  businessAddress?: any,
  isTrustee: boolean,
  displayText: string,
  isAto: boolean,
};

const defaultSuggestion = {
  id: '',
  entityName: '',
  isTrustee: false,
  displayText: '',
  isAto: false,
};

const AbnAutoSuggest = (props: Props) => {
  const {
    width,
    getSuggestions,
    onSuggestionSelected,
    delay,
    onKeyPress,
    onKeyUp,
    autoFocus,
    fieldValue,
    searchMode,
    showGrid = true,
    defaultSelectedAbn = '',
  } = props;
  const classes = useStyles();
  const isMobile = isMobileResolution(width);
  const [stateSelected, setStateSelected] = React.useState<EntitySuggestionItem>(defaultSuggestion);
  const [stateSuggestions, setStateSuggestions] = React.useState({
    rows: [],
    search: '',
  });

  useEffect(() => {
    if (!fieldValue) {
      setStateSelected(defaultSuggestion);
    }
  }, [fieldValue]);

  const transformSuggestions = useCallback((suggestionsParam) => {
    let topSuggestions = [];
    const { rows, search } = suggestionsParam;
    if (rows && rows.length > 0) {
      const keywordLowerCase = search.toLowerCase();
      topSuggestions = rows.slice(0, 50).map((x) => {
        if (x.acn && x.acn === search) {
          const displayText = `${x.entityName} ACN: ${formatAbnOrAcn(x.acn)}`;
          const enriched = { displayText, isTrustee: true };
          Object.assign(enriched, x);
          return enriched;
        }
        const displayText = `${x.entityName} ABN: ${formatAbnOrAcn(x.id)}`;
        const businessName = x.businessNames ? x.businessNames.filter((item) => item.toLowerCase().indexOf(keywordLowerCase) > -1) : '';
        const enriched = { displayText: businessName.length > 0 ? `${displayText} (trading as ${businessName})` : displayText, isTrustee: false };
        Object.assign(enriched, x);
        return enriched;
      });
    }

    return topSuggestions;
  }, []);

  const handleOnSuggestionSelected = (event, data: { suggestion: any }) => {
    setStateSelected(data.suggestion);
    if (onSuggestionSelected) {
      onSuggestionSelected(event, data);
    }
  };

  useEffect(() => {
    if (!defaultSelectedAbn) {
      return;
    }

    getSuggestions(defaultSelectedAbn).then((response) => {
      const { error, payload } = response;
      if (error) {
        const data = {
          rows: [],
          search: defaultSelectedAbn,
        };
        setStateSuggestions(data);
      } else {
        let rows;
        if (searchMode === 'ShiftSearch') {
          rows = payload.data.map((x) => ({
            ...x,
            id: x.abn,
            entityName: x.accountName,
          }));
        } else {
          rows = payload.data.output;
        }
        const data = {
          rows,
          search: defaultSelectedAbn,
        };
        setStateSuggestions(data);
        const transformedSuggestions = transformSuggestions(data);
        if (defaultSelectedAbn !== '') {
          handleOnSuggestionSelected(({}), { suggestion: transformedSuggestions[0] });
        }
      }
    });
  }, [defaultSelectedAbn]);

  const handleGetSuggestions = (search: string) => {
    setStateSelected(defaultSuggestion);
    setStateSuggestions({
      rows: [],
      search,
    });

    getSuggestions(search).then((response) => {
      const { error, payload } = response;
      if (error) {
        const data = {
          rows: [],
          search,
        };
        setStateSuggestions(data);
      } else {
        let rows;
        if (searchMode === 'ShiftSearch') {
          rows = payload.data.map((x) => ({
            ...x,
            id: x.abn,
            entityName: x.accountName,
          }));
        } else {
          rows = payload.data.output;
        }
        const data = {
          rows,
          search,
        };
        setStateSuggestions(data);
      }
    });
  };

  const renderSelectedDetails = () => {
    if (!showGrid || !stateSelected || !stateSelected.id) {
      return null;
    }

    // $FlowFixMe
    const businessNumberFieldLabel = stateSelected && stateSelected.id === stateSelected.acn && stateSelected.isTrustee ? 'ACN' : 'ABN';
    if (isMobile) {
      return (
        <Box className={classes.mobileDetailContainer}>
          <Typography>{businessNumberFieldLabel}</Typography>
          <Typography variant='subtitle2' className={classes.mobileDetailValue}>
            <AbnText value={stateSelected.id} />
          </Typography>
          <Typography>Name</Typography>
          <Typography variant='subtitle2' className={classes.mobileDetailValue}>
            {stateSelected.entityName}
          </Typography>
          {searchMode === 'AbnSearch' && stateSelected.businessAddress ? (
            <>
              <Typography>Location</Typography>
              <Typography variant='subtitle2'>{`${stateSelected.businessAddress.postcode} ${stateSelected.businessAddress.state}`}</Typography>
            </>
          ) : null}
        </Box>
      );
    }

    return (
      <Paper className={classes.desktopDetailContainer}>
        <Table>
          <TableHead>
            <TableHeadRow>
              <TableHeadCell>{businessNumberFieldLabel}</TableHeadCell>
              <TableHeadCell>Name</TableHeadCell>
              {searchMode === 'AbnSearch' && <TableHeadCell>Location</TableHeadCell>}
            </TableHeadRow>
          </TableHead>
          <TableBody>
            <TableBodyRow>
              <TableBodyCell>
                <AbnText value={stateSelected.id} />
              </TableBodyCell>
              <TableBodyCell>{stateSelected.entityName}</TableBodyCell>
              {searchMode === 'AbnSearch' && stateSelected.businessAddress ? (
                <TableBodyCell>{`${stateSelected.businessAddress.postcode} ${stateSelected.businessAddress.state}`}</TableBodyCell>
              ) : null}
            </TableBodyRow>
          </TableBody>
        </Table>
      </Paper>
    );
  };

  return (
    <React.Fragment>
      <AutoSuggestInput
        fieldValue={fieldValue || (defaultSelectedAbn && stateSelected.displayText) || ''}
        getSuggestions={handleGetSuggestions}
        getSuggestionValue={(x) => x.displayText}
        onSuggestionSelected={handleOnSuggestionSelected}
        suggestions={transformSuggestions(stateSuggestions)}
        delay={delay}
        onKeyPress={onKeyPress}
        onKeyUp={onKeyUp}
        autoFocus={autoFocus}
        inputProps={{
          startAdornment: <SearchIcon className={classes.searchIcon} />,
        }}
      />
      {renderSelectedDetails()}
    </React.Fragment>
  );
};

AbnAutoSuggest.defaultProps = {
  delay: 1200,
  onKeyPress: undefined,
  onKeyUp: undefined,
  autoFocus: false,
  searchMode: 'AbnSearch',
};

export default withWidth()(AbnAutoSuggest);
