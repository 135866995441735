import { Context, createContext } from 'react';

import { type File } from '../../types';

export type FileUploadContextProps = {
  maxFilesCount: number;
  maxFileSizeInMb: number;
  onFileUpload: (gcAccountId: string, fileGroupId: string, fileName: string) => Promise<unknown>;
  fileGroupId: string;
  gcAccountId: string;
  canUpload: (file: File) => boolean;
  fileCount: number;
  supportedFileTypesText?: string;
};

const FileUploadContext: Context<FileUploadContextProps> = createContext<FileUploadContextProps>({
  maxFilesCount: 0,
  maxFileSizeInMb: 0,
  onFileUpload: function (gcAccountId: string, fileGroupId: string, fileName: string): Promise<unknown> {
    throw new Error('Function not implemented.');
  },
  fileGroupId: '',
  gcAccountId: '',
  canUpload: function (file: File): boolean {
    throw new Error('Function not implemented.');
  },
  fileCount: 0,
});

export default FileUploadContext;
