// @flow
import { handleActions } from 'redux-actions';
import moment from 'moment';

import { PARTITION, actionTypes, ACTION_PREFIX } from './constants';
import { getActionOptions } from '../../../lib/reduxActionsUtils';
import type { Invoice } from '../Review/types';

export type ReduxState = {
  invoice: Invoice,
  isAllowedJoinBrokerNetwork: boolean,
};

const initialState: ReduxState = {
  invoice: {
    id: '',
    created: moment().toDate(),
    nextInstallmentDate: moment().toDate(),
    orderId: '',
    amount: 0,
    attachments: [],
    invoiceDescription: '',
    invoiceNumber: '',
    supplierGcAccountId: '',
    supplierAccountName: '',
    supplierAbn: '',
    supplierBsb: '',
    supplierBankAccountNumber: '',
    supplierContactId: '',
    fileGroupId: '',
    isNewSupplier: false,
    invoicePrn: '',
    isDirector: false,
  },
  isAllowedJoinBrokerNetwork: false,
};

const reducer = handleActions<ReduxState>(
  {
    [actionTypes.ADD_INVOICE_FULFILLED]: (state, { payload }) => {
      return {
      ...state,
      invoice: {
        created: payload.created,
        orderId: '',
        amount: payload.invoiceAmount,
        attachments: payload.invoiceFiles,
        invoiceDescription: payload.invoiceDescription,
        invoiceNumber: payload.invoiceNumber,
        activeGcAccountId: payload.activeGcAccountId,
        supplierGcAccountId: payload.supplierGcAccountId,
        supplierAccountName: payload.supplierAccountName,
        supplierAbn: payload.supplierAbn,
        supplierBsb: payload.supplierBsb,
        supplierBankAccountNumber: payload.supplierBankAccountNumber,
        supplierContactId: payload.supplierContactId,
        fileGroupId: payload.fileGroupId,
        isNewSupplier: payload.isNewSupplier,
        isDirector: payload.isDirector,
        isEditSupplier: payload.isEditSupplier,
        disbursalMethod: payload.disbursalMethod,
        disburseDate: payload.disburseDate,
      },
    }},
    [actionTypes.SET_ALLOW_JOIN_BROKER_NETWORK]: (state, { payload }) => ({
      ...state,
      isAllowedJoinBrokerNetwork: payload,
    }),
  },
  initialState,
  getActionOptions(ACTION_PREFIX)
);

export default {
  [PARTITION]: reducer,
};
