import { ShiftTheme, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';

import { useAppDispatch } from 'src/store';

import actions from './actions';
import SupplierProfile from './SupplierProfile';
import { type EntitySuggestionItem } from '../../../components/AbnAutoSuggest';
import BusinessSearch from '../../../components/BusinessSearch/Component';
import { EntityMode } from '../../../components/BusinessSearch/constants';
import initialDataSelectors from '../../../components/InitialData/selectors';
import { type Supplier } from '../../../types';

const useStyles = makeStyles((theme: ShiftTheme) => ({
  formControl: {
    minWidth: '100%',
  },
  title: {
    marginBottom: theme.spacing(2.5),
  },
  orLabel: {
    alignItems: 'center',
    display: 'flex',
    justifyContent: 'center',
    [theme.breakpoints.down('sm')]: {
      margin: theme.spacing(1.5),
    },
  },
  payNewSupplierButton: {
    marginTop: theme.spacing(0.5),
  },
}));

export type SupplierBankAccount = {
  bsb: string;
  accountNumber: string;
};

type Props = {
  onExistingSupplierSelected: (supplier: Supplier) => void;
  onNewSupplierSelected: (entity: EntitySuggestionItem, validationResponse: unknown, relatedEntity?: EntitySuggestionItem) => void;
  searchDelay: number;
  defaultSelectedAbn: string;
  isAto: boolean;
};

const SupplierSearch = (props: Props) => {
  const { onExistingSupplierSelected, onNewSupplierSelected, searchDelay = 1000, defaultSelectedAbn = '', isAto } = props;
  const classes = useStyles();
  const [supplier, setSupplier] = useState<Supplier>({ abn: '', gcAccountId: '', accountName: '', isAto: false });
  const [supplierBankAccount, setSupplierBankAccount] = useState<SupplierBankAccount | null>(null);
  const dispatch = useAppDispatch();
  const { supplierGcAccountId } = useParams<{ supplierGcAccountId: string }>();
  const activeGcAccountId = useSelector(initialDataSelectors.selectSelectedGcAccountId) ?? '';

  useEffect(() => {
    if (!supplierGcAccountId) {
      return;
    }

    dispatch(actions.getSupplier(activeGcAccountId, supplierGcAccountId)).then((response) => {
      const { payload, error } = response;

      if (!error && payload.data.suppliers.length > 0) {
        const selectedSupplier = payload.data.suppliers[0];
        setSupplier(selectedSupplier);
        onExistingSupplierSelected(selectedSupplier);
      }
    });

    dispatch(actions.getSupplierBankAccount(activeGcAccountId, supplierGcAccountId)).then((response) => {
      const { payload, error } = response;

      if (!error) {
        setSupplierBankAccount(payload.data);
      }
    });
  }, [supplierGcAccountId]);

  const handleSuggestionSelectionChanged = (entity: EntitySuggestionItem, validationResponse: unknown, relatedEntity?: EntitySuggestionItem) => {
    onNewSupplierSelected(entity, validationResponse, relatedEntity);
  };

  return (
    <>
      {supplierGcAccountId && supplierBankAccount ? (
        <SupplierProfile
          abn={supplier.abn}
          accountName={supplier.accountName}
          bsb={supplierBankAccount.bsb}
          bankAccountNumber={supplierBankAccount.accountNumber}
          supplierGcAccountId={supplierGcAccountId}
          isAto={isAto}
        />
      ) : null}
      {!supplierGcAccountId && (
        <>
          <Typography variant='h3' component='h3' className={classes.title}>
            Pay a new supplier
          </Typography>
          <BusinessSearch
            searchLabel="Enter supplier's ABN or Business/Trading name"
            selectionMode={EntityMode.Default}
            searchDelay={searchDelay}
            defaultSelectedAbn={defaultSelectedAbn}
            enableTrustAndTrustee={false}
            onSelectionChanged={handleSuggestionSelectionChanged}
          />
        </>
      )}
    </>
  );
};

export default SupplierSearch;
