// @flow
import moment from 'moment';
import Axios from 'axios';
import { traceInfo } from '../lib/telemetryUtils';

import { type UserProfileModeEnum, type BuyerRequestTypeEnum, BuyerRequestType, type ReferralAccount, type ExtendInvoiceRequest } from '../types';
import baseApi from './baseApi';
import startupConfig from '../lib/startupConfig';
import { v4 as uuidv4 } from 'uuid';

export type OrderBy = {
  columnName: string,
  isAscending: boolean,
};

export const PaymentMethodValues = {
  Full: 'Full',
  Weekly: 'Weekly',
};

export type PaymentMethod = $Keys<typeof PaymentMethodValues>;

export const WeekdayValues = {
  Monday: 'Monday',
  Tuesday: 'Tuesday',
  Wednesday: 'Wednesday',
  Thursday: 'Thursday',
  Friday: 'Friday',
};

export type Weekday = $Keys<typeof WeekdayValues>;

export const AuthorizationStatusValues = {
  Required: 'Required',
  NotRequired: 'NotRequired',
};

export type AuthorizationStatus = $Keys<typeof AuthorizationStatusValues>;

const convertOrderByToQueryString = (orderBy: ?Array<OrderBy>) => {
  return orderBy ? orderBy.map((x) => `&orderBy=${x.columnName} ${x.isAscending ? 'asc' : 'desc'}`).join('') : '';
};

const get = (partUrl: string, apiVersion: ?string = undefined, isPolling: boolean = false): Promise<any> => {
  const config = startupConfig.get();
  const baseUrl = config ? config.shiftServiceUrl || '' : '';
  return baseApi.get({ url: `${baseUrl}/api${partUrl}`, apiVersion, isPolling });
};

const post = (partUrl: string, data: any, apiVersion: ?string = undefined, requestId: ?string = undefined): Promise<any> => {
  const config = startupConfig.get();
  const baseUrl = config ? config.shiftServiceUrl || '' : '';
  return baseApi.post({ url: `${baseUrl}/api${partUrl}`, data, apiVersion, requestId });
};

const deleteRequest = (partUrl: string, apiVersion: ?string = undefined): Promise<any> => {
  const config = startupConfig.get();
  const baseUrl = config ? config.shiftServiceUrl || '' : '';
  return baseApi.delete({ url: `${baseUrl}/api${partUrl}`, apiVersion });
};

const patch = (partUrl: string, data?: any, apiVersion: ?string = undefined): Promise<any> => {
  const config = startupConfig.get();
  const baseUrl = config ? config.shiftServiceUrl || '' : '';
  return baseApi.patch({
    url: `${baseUrl}/api${partUrl}`,
    data,
    apiVersion,
  });
};

const getInvoiceAttachment = (gcAccountId: string, filePath: string): Promise<any> => {
  const config = startupConfig.get();
  const baseUrl = config ? config.shiftServiceUrl || '' : '';
  const url = `${baseUrl}/api/attachments/${gcAccountId}/${encodeURIComponent(filePath)}`;
  return baseApi.get({
    url,
    responseType: 'blob',
  });
};

const getStartupConfig = () => {
  const config = startupConfig.get();
  const baseUrl = config ? config.shiftServiceUrl || '' : '';
  return baseApi.get({
    url: `${baseUrl}/api/configuration/startup`,
  });
};

const getAccount = (gcAccountId: string) => get(`/accounts/${gcAccountId}`);

const getAccountName = (gcAccountId: string) => get(`/accounts/name?gcAccountId=${encodeURIComponent(gcAccountId)}`, '2.0');

const getContactInvitations = (gcAccountId: string) => get(`/contacts/invitations?gcAccountId=${gcAccountId}`, '2.0');

const getInvoice = (id: string, gcAccountId: string) => get(`/invoices/${id}?gcAccountId=${gcAccountId}`);

const getFees = (supplierGcAccountId: string, buyerGcAccountId: string, amount: number) =>
  get(`/invoices/fees?supplierGcAccountId=${supplierGcAccountId}&buyerGcAccountId=${buyerGcAccountId}&amount=${amount.toFixed(2)}`);

const getTermsFeesAndPaymentTypes = (supplierGcAccountId: string, buyerGcAccountId: string, amount: number) =>
  get(`/invoices/feesandpaymenttypes?supplierGcAccountId=${supplierGcAccountId}&buyerGcAccountId=${buyerGcAccountId}&amount=${amount.toFixed(2)}`);

const getTransactionFees = (supplierGcAccountId: string, buyerGcAccountId: string, amount: number, scheduledDate: ?Date) => {
  let url = `/invoices/transaction-fees?supplierGcAccountId=${supplierGcAccountId}&buyerGcAccountId=${buyerGcAccountId}&amount=${amount.toFixed(
    2
  )}`;
  if (scheduledDate) {
    url += `&scheduledDateUtc=${moment(scheduledDate).utc().format('YYYY-MM-DD')}`;
  }
  return get(url);
};

const invite = (gcAccountId: string, recipientGCContactId: string, mobileNumber: string, channel: string): Promise<any> =>
  post(`/contacts/${recipientGCContactId}/invite`, {
    mobileNumber,
    channel,
    gcAccountId,
  });

const getAgreement = (agreementType: string) => get(`/agreements/${agreementType}`);

const setupBankAccount = (
  gcAccountId: string,
  bankAccount: {
    accountNumber: string,
    accountName: string,
    bsb: string,
    lbfcId: ?string,
    bankAccountId: string,
    select: boolean,
  }
) => post(`/bank-accounts?gcAccountId=${gcAccountId}`, bankAccount);

const signLoanAgreement = (gcAccountId: string, agreementVersion: string) => post(`/agreements/${gcAccountId}/loan/${agreementVersion}`);

const signPrivacyAgreement = (gcAccountId: string, agreementVersion: string) => post(`/agreements/${gcAccountId}/privacy/${agreementVersion}`);

const signConsentTermsAndConditionsAgreement = (gcAccountId: string, agreementVersion: string) =>
  post(`/agreements/${gcAccountId}/ConsentTermsAndConditions/${agreementVersion}`);

const getBalanceSummary = (gcAccountId: string) => get(`/payments/balance?gcAccountId=${gcAccountId}`);

const getMySettings = (gcAccountId: string, isPolling: boolean = false) => {
  return get(`/accounts/${gcAccountId}/settings`, '2.0', isPolling);
};

const setRepaymentDay = (gcAccountId: string, repaymentDay: Weekday, smsReminder: boolean) =>
  post(`/accounts/${gcAccountId}/settings`, { repaymentDay, smsReminder }, '2.0');

const setSmsReminder = (gcAccountId: string, smsReminder: boolean) => post(`/accounts/${gcAccountId}/settings`, { smsReminder }, '2.0');

const setCreditLimitOption = (gcAccountId: string, isNewLimitBasedOnSales: boolean, newAccountLimit: ?number) => {
  const data = {
    isNewLimitBasedOnSales,
    requestedLimit: newAccountLimit,
  };
  return patch(`/accounts/${gcAccountId}/credit-limit-option`, data, '2.0');
};

const requestAccountLimitIncrease = (gcAccountId: string, newAccountLimit: number) =>
  post(`/accounts/${gcAccountId}/new-credit-limit-request`, { newAccountLimit }, '2.0');

const getCustomers = (gcAccountId: string, search: string, skip: number, limit: number, orderBy: Array<OrderBy>) => {
  let url = `/accounts/${gcAccountId}/buyers?skip=${skip}&limit=${limit}`;
  if (search) {
    url += `&search=${encodeURIComponent(search)}`;
  }

  url += convertOrderByToQueryString(orderBy);

  return get(url);
};

const accountsQuickSearch = (gcAccountId: string, search: string, searchMode: UserProfileModeEnum) => {
  const url = `/accounts/${gcAccountId}/quick-search?search=${search}&searchMode=${searchMode}`;
  return get(url);
};

const getExtendableInvoices = (gcAccountId: string) => {
  const url = `/invoices/extendable/${gcAccountId}`;
  return get(url, '2.0');
};

const appendContractFilters = (url: string, search: ?string, from: ?Date, to: ?Date, orderBy: ?Array<OrderBy>) => {
  let urlWithContractFilters = url;
  if (search) {
    urlWithContractFilters += `&search=${search}`;
  }
  if (from) {
    urlWithContractFilters += `&from=${moment(from).format('YYYY-MM-DD')}`;
  }
  if (to) {
    urlWithContractFilters += `&to=${moment(to).format('YYYY-MM-DD')}`;
  }
  if (orderBy) {
    urlWithContractFilters += convertOrderByToQueryString(orderBy);
  }
  return urlWithContractFilters;
};

const getPayments = (
  gcAccountId: string,
  profileMode: UserProfileModeEnum,
  skip: number,
  limit: number,
  search: ?string,
  from: ?Date,
  to: ?Date,
  orderBy: ?Array<OrderBy>,
  pendingOnly: boolean = false
) => {
  let url = `/payments?gcAccountId=${gcAccountId}&profileMode=${profileMode}&skip=${skip}&limit=${limit}&pendingOnly=${String(pendingOnly)}`;
  url = appendContractFilters(url, search, from, to, orderBy);
  return get(url);
};

const getInvoices = (
  gcAccountId: string,
  skip: number,
  limit: number,
  search: ?string,
  from: ?Date,
  to: ?Date,
  orderBy: ?Array<OrderBy>,
  pendingOnly: boolean = false
) => {
  return getV2Invoices(gcAccountId, skip, limit, search, from, to, orderBy, pendingOnly);
};

const getV2Invoices = (
  gcAccountId: string,
  skip: number,
  limit: number,
  search: ?string,
  from: ?Date,
  to: ?Date,
  orderBy: ?Array<OrderBy>,
  pendingOnly: boolean = false
) => {
  let url = `/invoices?gcAccountId=${gcAccountId}&skip=${skip}&limit=${limit}&pendingOnly=${String(pendingOnly)}`;
  url = appendContractFilters(url, search, from, to, orderBy);
  return get(url, '2.0');
};

const getScheduledInvoices = (gcAccountId: string, skip: number, limit: number, orderBy: OrderBy[] | null) => {
  let url = `/invoices/scheduled?gcAccountId=${gcAccountId}&skip=${skip}&limit=${limit}`;
  url = appendContractFilters(url, orderBy);
  return get(url, '2.0');
};

const getSupplierRequests = (gcAccountId: string, skip: number, limit: number, orderBy: Array<OrderBy>) => {
  let url = `/supplier-requests/${gcAccountId}/pending?skip=${skip}&limit=${limit}`;
  url += convertOrderByToQueryString(orderBy);
  return get(url, '2.0');
};

const getSupplierRequest = (supplierGcAccountId: string, buyerGcAccountId: string) => {
  return get(`/supplier-requests/${supplierGcAccountId}/${buyerGcAccountId}`);
};

const approveSupplierRequest = (supplierGcAccountId: string, buyerGcAccountId: string) => {
  return post(`/supplier-requests/${supplierGcAccountId}/${buyerGcAccountId}/approve`);
};

const rejectSupplierRequest = (supplierGcAccountId: string, buyerGcAccountId: string) => {
  return deleteRequest(`/supplier-requests/${supplierGcAccountId}/${buyerGcAccountId}/reject`, '2.0');
};

const cancelSupplierRequest = (supplierGcAccountId: string, buyerGcAccountId: string) => {
  return deleteRequest(`/supplier-requests/${supplierGcAccountId}/${buyerGcAccountId}/cancel`, '2.0');
};

const getV1Invoices = (
  gcAccountId: string,
  skip: number,
  limit: number,
  search: ?string,
  from: ?Date,
  to: ?Date,
  orderBy: ?Array<OrderBy>,
  pendingOnly: boolean = false
) => {
  let url = `/invoices?gcAccountId=${gcAccountId}&skip=${skip}&limit=${limit}&pendingOnly=${String(pendingOnly)}`;
  url = appendContractFilters(url, search, from, to, orderBy);
  return get(url);
};

const getPendingInvoices = (gcAccountId: string, skip: number, limit: number, orderBy: ?Array<OrderBy>) => {
  const sixMonthsAgo = moment().subtract(6, 'months').toDate();
  const now = moment().toDate();

  return getV1Invoices(gcAccountId, skip, limit, null, sixMonthsAgo, now, orderBy, true);
};

const getPaymentsSummary = (gcAccountId: string, profileMode: UserProfileModeEnum, from: Date, to: Date) => {
  let url = `/payments?gcAccountId=${gcAccountId}&profileMode=${profileMode}`;
  if (from) {
    url += `&from=${moment(from).format('YYYY-MM-DD')}`;
  }
  if (to) {
    url += `&to=${moment(to).format('YYYY-MM-DD')}`;
  }
  return get(url);
};

const payBalance = (balance: number, salesforceRecordId: string, gcAccountId: string) => {
  const data = {
    payOffAmount: balance,
    sfBankAccountRecordId: salesforceRecordId,
  };
  return post(`/payments/pay-balance/${gcAccountId}`, data, '2.0');
};

const uploadInvoiceFile = (gcAccountId: string, fileGroupId: string, file: any) => {
  const data = new FormData();
  data.append('file', file);

  const config = startupConfig.get();
  const baseUrl = config ? config.shiftServiceUrl || '' : '';
  const url = `${baseUrl}/api/attachments/${gcAccountId}/${fileGroupId}`;
  return baseApi.post({ url, data, axiosInstance: Axios.create() });
};

const removeInvoiceFiles = (gcAccountId: string, fileGroupId: string) => {
  const url = `/attachments/${gcAccountId}/${fileGroupId}`;
  return deleteRequest(url);
};

const removeInvoiceFile = (gcAccountId: string, fileGroupId: string, fileName: string) => {
  const url = `/attachments/${gcAccountId}/${fileGroupId}/${fileName}`;
  return deleteRequest(url);
};

const createDocument = (gcAccountId, requestData) => {
  const url = `/documents/${gcAccountId}`;
  return post(url, requestData);
};

const uploadDocumentFile = (gcAccountId, documentId, file) => {
  const data = new FormData();
  data.append('file', file);

  const config = startupConfig.get();
  const baseUrl = config ? config.shiftServiceUrl || '' : '';
  const url = `${baseUrl}/api/documents/upload/${gcAccountId}/${documentId}`;
  return baseApi.post({ url, data, contentType: 'multipart/form-data', axiosInstance: Axios.create() });
};

const deleteDocumentFile = (gcAccountId, documentFileId) => {
  const apiUrl = `/documents/file/${gcAccountId}/${documentFileId}`;
  return deleteRequest(apiUrl);
};

const getSuppliers = (buyerGcAccountId: string, supplierGcAccountId: string = '') => {
  let url = `/buyer-accounts/${buyerGcAccountId}/suppliers/quick-search`;
  if (supplierGcAccountId) {
    url += `?supplierGcAccountId=${supplierGcAccountId}`;
  }
  return get(url);
};

const getUpcomingPayments = (gcAccountId: string, skip: number, limit: number, orderBy: Array<OrderBy>) => {
  let url = `/payments/schedules?gcAccountId=${gcAccountId}&skip=${skip}&limit=${limit}`;
  url += convertOrderByToQueryString(orderBy);
  return get(url);
};

const getPaymentHistory = (gcAccountId: string, skip: number, limit: number, orderBy: Array<OrderBy>) => {
  let url = `/payments/history?gcAccountId=${gcAccountId}&skip=${skip}&limit=${limit}`;
  url += convertOrderByToQueryString(orderBy);
  return get(url);
};

const getBankAccounts = (gcAccountId: string, all: boolean = false) => get(`/bank-accounts?gcAccountId=${gcAccountId}&all=${all.toString()}`);

const addCustomer = (
  supplierGcAccountId: string,
  contactId: string,
  contactFirstName: string,
  contactLastName: string,
  mobileNumber: string,
  emailAddress: string,
  avgMonthlyOrderValue: number,
  termInDays: number,
  termPeriod: string,
  buyerAbnOrAcn: string,
  relatedEntityAbnOrAcn: string,
  requestType: BuyerRequestTypeEnum,
  isRelatedEntityATrustee: boolean,
  isAutoBillingEnabled: boolean
) => {
  const data =
    requestType === BuyerRequestType.KnownContact
      ? {
          contactId,
          contactFirstName: null,
          contactLastName: null,
          mobileNumber,
          emailAddress,
          averageMonthlyOrderValue: avgMonthlyOrderValue,
          termInDays,
          termPeriod,
          buyerAbnOrAcn,
          relatedEntityAbnOrAcn,
          requestType,
          isRelatedEntityATrustee,
          isAutoBillingEnabled,
        }
      : {
          contactId: null,
          contactFirstName,
          contactLastName,
          mobileNumber,
          emailAddress,
          averageMonthlyOrderValue: avgMonthlyOrderValue,
          termInDays,
          termPeriod,
          buyerAbnOrAcn,
          relatedEntityAbnOrAcn,
          requestType,
          isRelatedEntityATrustee,
          isAutoBillingEnabled,
        };
  return post(`/supplier-requests/${supplierGcAccountId}/buyers`, data);
};

const getSupplierTermFeeLookup = (gcAccountId: string) => get(`/suppliertermfeelookup?gcAccountId=${gcAccountId}`, '2.0');

const getContacts = (gcAccountId: string) => get(`/contacts?gcAccountId=${gcAccountId}`);

const getInvitationSenderAndRecipient = (
  senderGcAccountId: string,
  senderGcContactId: string,
  recipientGcAccountId: string,
  recipientGcContactId: string
) => {
  const url =
    `/contacts/invitation?senderGcAccountId=${encodeURIComponent(senderGcAccountId)}&senderGcContactId=${senderGcContactId}&` +
    `recipientGcAccountId=${encodeURIComponent(recipientGcAccountId)}&recipientGcContactId=${recipientGcContactId}`;
  return get(url, '2.0');
};

const validateNewBuyer = (supplierGcAccountId: string, buyerAbnOrAcn: string, isTrustee: any) => {
  return get(`/accounts/${supplierGcAccountId}/validate-new-buyer/${buyerAbnOrAcn}?isTrustee=${isTrustee}`);
};

const validateRelatedEntity = (supplierGcAccountId: string, buyerAbnOrAcn: string, relatedEntityAbnOrAcn: string, isRelatedEntityATrustee: any) => {
  return get(
    `/accounts/${supplierGcAccountId}/validate-new-buyer/${buyerAbnOrAcn}/relatedentity/` +
      `${relatedEntityAbnOrAcn}?isRelatedEntityATrustee=${isRelatedEntityATrustee}`
  );
};

const getPublicSuppliers = (buyerGcAccountId: string, search: string, skip: number, limit: number, orderBy: ?Array<OrderBy>) => {
  let url = `/buyer-accounts/${buyerGcAccountId}/suppliers?skip=${skip}&limit=${limit}`;

  if (search) {
    url += `&search=${search}`;
  }

  url += convertOrderByToQueryString(orderBy);
  return get(url);
};

const validatePaymentBuyer = (supplierGcAccountId: string, buyerGcAccountId: string) => {
  const url = `/payments/${supplierGcAccountId}/validate-buyer/${buyerGcAccountId}`;
  return get(url, '2.0');
};

const shareInvoice = (
  gcAccountId: string,
  email: ?string,
  loanAmount: ?number,
  isToDirector: boolean,
  invoiceNumber: string,
  supplierName: string,
  effectiveDate: string
) => {
  return post('/invoices/shareinvoice', {
    gcAccountId,
    email,
    loanAmount,
    isToDirector,
    invoiceNumber,
    supplierName,
    effectiveDate,
  });
};

const editSupplierBankAccount = (buyerGcAccountId: string, supplierAbnOrAcn: string, bsb: string, bankAccountNumber: number) => {
  return post(`/buyer-accounts/${buyerGcAccountId}/suppliers/edit-supplier-bank-account`, {
    supplierAbnOrAcn,
    bsb,
    bankAccountNumber,
  });
};

const requestSupplierAccess = (gcAccountId: string, customerBase: string, terms: string, avgTransactionSize: number) => {
  return post('/accounts/supplier-access', {
    gcAccountId,
    customerBase,
    terms,
    averageTransactionSize: avgTransactionSize,
  });
};

const getAccountSetup = (gcAccountId: string) => {
  const url = `/accounts/${gcAccountId}/setup`;
  return get(url);
};

const autoApproveSupplierRequest = (buyerGcAccountId: string) => {
  return patch(`/supplier-requests/${buyerGcAccountId}/autoapprove`);
};

const addReferralAccountRelation = (buyerGcAccountId: string, referrerAccount: ReferralAccount = {}) => {
  let url = `/supplier-requests/${buyerGcAccountId}/link-referrer`;
  return patch(url, referrerAccount);
};

const getRolePermissionFilteredByHierarchy = (gcAccountId: string) => {
  const url = `/accounts/${gcAccountId}/role-permission-map`;
  return get(url);
};

const setSupplierAuthorization = (
  buyerGcAccountId: string,
  supplierGcAccountId: string,
  authorizationStatus: AuthorizationStatus,
  reason: ?string = null
) => patch(`/accounts/${buyerGcAccountId}/suppliers/${supplierGcAccountId}/authorization`, { authorizationStatus, reason });

const addStaff = (staffs: Array<any>, gcAccountId: string) => {
  const data = {
    staffs,
  };
  const url = `/accounts/${gcAccountId}/staffs`;
  return post(url, data);
};

const setStaffPageVisibility = (gcAccountId: string) => {
  const data = {
    showAddStaffPage: false,
  };
  return patch(`/accounts/${gcAccountId}/staff-page-visibility`, data, '2.0');
};

const getStaffPageVisibility = (gcAccountId: string) => {
  return get(`/accounts/${gcAccountId}/staff-page-visibility`, '2.0');
};

const getContactDetail = (gcContactId: string, isRequireDataRefresh: any) => {
  return get(`/contacts/${gcContactId}?isRequireDataRefresh=${isRequireDataRefresh}`, '2.0');
};

const getBankNameByBsbNumber = (bsbNumber: string) => get(`/bank-accounts/bsb/${bsbNumber}`);

const getSupplierBankAccount = (buyerGcAccountId: string, supplierGcAccountId: string) => {
  return get(`/bank-accounts/supplier?buyerGcAccountId=${buyerGcAccountId}&supplierGcAccountId=${supplierGcAccountId}`);
};

const validateNewSupplier = (buyerGcAccountId: string, supplierAbnOrAcn: string, isTrustee: any) => {
  return get(`/buyer-accounts/${buyerGcAccountId}/validate-new-supplier/${supplierAbnOrAcn}?isTrustee=${isTrustee}`);
};

const validateNewSupplierRelatedEntity = (buyerGcAccountId: string, supplierAbnOrAcn: string, relatedEntityAbnOrAcn: string, isTrustee: any) => {
  return get(
    `/buyer-accounts/${buyerGcAccountId}/validate-new-supplier/${supplierAbnOrAcn}/related-entity/` +
      `${relatedEntityAbnOrAcn}?isTrustee=${isTrustee}`
  );
};

const addSupplier = (
  buyerGcAccountId: string,
  supplierAbnOrAcn: string,
  relatedEntityAbnOrAcn: string,
  isRelatedEntityATrustee: boolean,
  bsb: string,
  bankAccountNumber: string
) => {
  const data = {
    supplierAbnOrAcn,
    relatedEntityAbnOrAcn,
    isRelatedEntityATrustee,
    bsb,
    bankAccountNumber,
  };
  traceInfo('1 - Posting for addSupplier - UTC time is:' + new Date().toISOString());
  return post(`/buyer-accounts/${buyerGcAccountId}/suppliers`, data);
};

const getBuyerDetail = (supplierGcAccountId: string, buyerGcAccountId: string) => {
  return get(`/supplier-accounts/${supplierGcAccountId}/buyers/${buyerGcAccountId}`);
};

const bankLogin = (values: any) => post('/bank-accounts/bank-login', values);

const bankMfaLogin = (values: any) => post('/bank-accounts/mfa-login', values);

const getPopularBanks = () => get('/banks/popular');

const searchBanks = (searchTerm: string) => get(`/banks?search=${searchTerm}`);
const searchBanksFromBankData = (searchTerm: string) => get(`/banks?search=${searchTerm}&provider=ILLN`, '2.0');

const getBankLoginFields = (bankId: string) => get(`/banks/login-fields?bankid=${bankId}`);

const getCreditLimitPendingRequest = (gcAccountId: string, isPolling: boolean = false) => {
  const url = `/accounts/${gcAccountId}/credit-limit-pending-request`;
  return get(url, '2.0', isPolling);
};

const getFacilityLimits = (gcAccountId: string) => {
  const url = `/accounts/${gcAccountId}/facility-limits`;
  return get(url, '2.0');
};

const getArrearsPaymentDetails = (gcAccountId: string) => {
  const url = `/payments/arrears/${gcAccountId}`;
  return get(url, '2.0');
};

const updateDefaultTerms = (supplierGcAccountId: string, buyerGcAccountId: string, defaultTerm: number, termPeriod: string) => {
  const data = {
    defaultTerm: defaultTerm,
    defaultTermPeriod: termPeriod,
  };
  return patch(`/supplier-accounts/${supplierGcAccountId}/${buyerGcAccountId}/update-default-terms`, data, '2.0');
};

const deleteSupplierBuyer = (supplierGcAccountId: string, buyerGcAccountId: string) => {
  return deleteRequest(`/supplier-accounts/${supplierGcAccountId}/${buyerGcAccountId}`, '2.0');
};

const registerInterest = (supplierGcAccountId: string, gcContactId: string) => {
  const data = {
    gcContactId: gcContactId,
  };
  return post(`/supplier-accounts/${supplierGcAccountId}/register-interest`, data, '2.0');
};

const requestBusinessOverdraft = (gcAccountId: string) => post(`/accounts/${gcAccountId}/business-overdraft-request`, {}, '2.0');

const getInvoiceExtensionTermsAndFees = (invoiceId: string, supplierGcAccountId: string, buyerGcAccountId: string) =>
  get(`/invoices/${invoiceId}/extensionfees/${buyerGcAccountId}/?supplierGcAccountId=${supplierGcAccountId}`, '2.0');

const extendInvoice = (extendInvoiceRequest: ExtendInvoiceRequest) => {
  const { invoiceId, gcAccountId, isWeekly, term, transactionFee, repaymentDate } = extendInvoiceRequest;

  const data = {
    term,
    transactionFee: transactionFee.toFixed(2),
    isWeekly,
    repaymentDate,
  };

  const requestId = uuidv4();

  return post(`/invoices/${invoiceId}/extend/${gcAccountId}`, data, '2.0', requestId);
};

const getAnalyticsAccountSummary = (gcAccountId: string) => {
  return get(`/accounts/${gcAccountId}/analytics-account-summary`);
};

const requestChangeLimitWithOptions = (gcAccountId: string, newAccountLimit: number, cliOption: string, isPreApproved: boolean) =>
  post(`/accounts/${gcAccountId}/change-limit-with-options`, { newAccountLimit, cliOption, isPreApproved }, '2.0');

export default {
  getStartupConfig,
  getAccount,
  getAccountName,
  getMySettings,
  getContactInvitations,
  invite,
  getAgreement,
  setupBankAccount,
  signLoanAgreement,
  signPrivacyAgreement,
  signConsentTermsAndConditionsAgreement,
  setRepaymentDay,
  setSmsReminder,
  getCustomers,
  accountsQuickSearch,
  getPayments,
  getPaymentsSummary,
  getBalanceSummary,
  payBalance,
  getInvoices,
  getV1Invoices,
  getV2Invoices,
  getScheduledInvoices,
  getPendingInvoices,
  getSupplierRequests,
  getSupplierRequest,
  approveSupplierRequest,
  autoApproveSupplierRequest,
  rejectSupplierRequest,
  cancelSupplierRequest,
  getInvoice,
  uploadInvoiceFile,
  removeInvoiceFiles,
  removeInvoiceFile,
  createDocument,
  uploadDocumentFile,
  deleteDocumentFile,
  getFees,
  getSuppliers,
  getUpcomingPayments,
  getPaymentHistory,
  getBankAccounts,
  addCustomer,
  getSupplierTermFeeLookup,
  getContacts,
  getInvitationSenderAndRecipient,
  validateNewBuyer,
  validateRelatedEntity,
  getPublicSuppliers,
  getInvoiceAttachment,
  validatePaymentBuyer,
  shareInvoice,
  requestSupplierAccess,
  getAccountSetup,
  editSupplierBankAccount,
  setSupplierAuthorization,
  getRolePermissionFilteredByHierarchy,
  addReferralAccountRelation,
  addStaff,
  setStaffPageVisibility,
  getStaffPageVisibility,
  getContactDetail,
  getTermsFeesAndPaymentTypes,
  getTransactionFees,
  getBankNameByBsbNumber,
  getSupplierBankAccount,
  validateNewSupplier,
  validateNewSupplierRelatedEntity,
  addSupplier,
  getBuyerDetail,
  bankLogin,
  bankMfaLogin,
  getPopularBanks,
  searchBanks,
  searchBanksFromBankData,
  getBankLoginFields,
  setCreditLimitOption,
  requestAccountLimitIncrease,
  getCreditLimitPendingRequest,
  updateDefaultTerms,
  deleteSupplierBuyer,
  registerInterest,
  requestBusinessOverdraft,
  getInvoiceExtensionTermsAndFees,
  getExtendableInvoices,
  extendInvoice,
  getFacilityLimits,
  getAnalyticsAccountSummary,
  getArrearsPaymentDetails,
  requestChangeLimitWithOptions,
};
