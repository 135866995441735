import { Grid, Tab, Tabs, Typography, withWidth } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import React, { useState } from 'react';
import BasicPageLayout from '../../../components/BasicPageLayout';
import TabLabel from '../../../components/TabLabel';
import TabPanel from '../../../components/TabPanel';
import { Invoices } from '../Invoices';
import { Can } from '../../../lib/ability';
import { Permission } from 'src/constants';
import { Button } from '@shiftfinancial/design-system';
import { BankLinkDialog, useGetAnalyticsAccountSummary } from '../../../components/BankLinkDialog/';
import { useSelector } from 'react-redux';
import initialDataSelectors from '../../../components/InitialData/selectors';
import accountReadinessSelectors from '../../../components/AccountReadinessAlerts/selectors';
import { useHistory } from 'react-router-dom';
import { isMobileResolution } from 'src/lib/materialUiUtils';
import ScheduledInvoices from '../ScheduledInvoices/ScheduledInvoices';

const useStyles = makeStyles((theme) => ({
  right: {
    [theme.breakpoints.down('xs')]: {
      padding: '0 !important',
    },
    [theme.breakpoints.up('sm')]: {
      paddingTop: theme.spacing(2),
    },
    [theme.breakpoints.up('md')]: {
      paddingTop: theme.spacing(0),
    },
  },
  tab: {
    [theme.breakpoints.down('xs')]: {
      width: '50%',
    },
  },
  container: {
    [theme.breakpoints.down('xs')]: {
      margin: '0 !important',
      width: '100% !important',
    },
  },
  headerContainer: {
    [theme.breakpoints.down('xs')]: {
      margin: theme.spacing(2, 0),
    },
  },
  addButtonContainer: {
    fontSize: '14px',
    textAlign: 'end',
  },
  addButton: {
    [theme.breakpoints.down('xs')]: {
      marginRight: theme.spacing(1),
    },
  },
  header: {
    [theme.breakpoints.down('xs')]: {
      margin: theme.spacing(1),
    },
    marginBottom: theme.spacing(2),
  },
}));

type Props = {
  totalPendingInvoices: number,
  totalSupplierRequests: number,
  width: string,
};

const InvoicesContainer = (props: Props) => {
  const { totalPendingInvoices = 0, totalSupplierRequests = 0, width } = props;

  const isMobile = isMobileResolution(width);

  const classes = useStyles();
  const history = useHistory();
  const gcAccountId = useSelector(initialDataSelectors.selectSelectedGcAccountId);
  const activeAccountIsDelinquent = useSelector(initialDataSelectors.selectSelectedAccountIsDelinquent);
  const isAccountReady = useSelector(accountReadinessSelectors.selectIsAccountReady);

  const isActionBlocked = activeAccountIsDelinquent || !isAccountReady;
  const analyticsAccountSummary = useGetAnalyticsAccountSummary(gcAccountId ?? '');

  const [bankLinkEnforcementStatusDialog, setBankLinkEnforcementStatusDialog] = useState(false);
  const [activeTabIndex, setActiveTabIndex] = useState(0);

  const handleTabChange = (e: React.ChangeEvent<{}>, tabIndex: number) => {
    setActiveTabIndex(tabIndex);
  };

  const handleAddInvoice = () => {
    if (analyticsAccountSummary && analyticsAccountSummary.isRequireConnection) {
      setBankLinkEnforcementStatusDialog(true);
      return;
    }
    history.push('/buyer/payments/pay-new-or-existing');
  };

  return (
    <BasicPageLayout title='' noMargin>
      <Grid container className={classes.headerContainer}>
        <Grid item xs={6}>
          <Typography variant='h1' className={classes.header}>
            Invoices
          </Typography>
        </Grid>
        <Grid item className={classes.addButtonContainer} xs={6}>
          <Can I={Permission.Actions.Create} a={Permission.Buyer.Invoice}>
            <Button
              appearance='primary'
              data-testid='uia-invoices-addButton'
              className={classes.addButton}
              disabled={isActionBlocked}
              onClick={handleAddInvoice}
            >
              {isMobile ? '+' : 'Pay a supplier'}
            </Button>
          </Can>
        </Grid>
      </Grid>
      {bankLinkEnforcementStatusDialog ? (
        <BankLinkDialog
          onClose={() => {
            setBankLinkEnforcementStatusDialog(false);
          }}
        />
      ) : null}
      <Tabs value={activeTabIndex} indicatorColor='secondary' onChange={handleTabChange}>
        <Tab
          label={<TabLabel label='Paid to supplier' badgeLabel={totalPendingInvoices.toString()} showBadge={totalPendingInvoices > 0} />}
          fullWidth={true}
          className={classes.tab}
        />
        <Tab
          label={<TabLabel label='Scheduled' badgeLabel={totalSupplierRequests.toString()} showBadge={totalSupplierRequests > 0} />}
          className={classes.tab}
        />
      </Tabs>
      <TabPanel tabIndex={0} activeTabIndex={activeTabIndex}>
        <Invoices />
      </TabPanel>
      <TabPanel tabIndex={1} activeTabIndex={activeTabIndex}>
        <ScheduledInvoices gcAccountId={gcAccountId ?? ''} />
      </TabPanel>
    </BasicPageLayout>
  );
};

export default withWidth()(InvoicesContainer);
