/* eslint-disable flowtype/no-types-missing-file-annotation */
import { createActions } from 'redux-actions';
import { ACTION_PREFIX, actionTypes } from './constants';
import { getActionOptions } from '../../../lib/reduxActionsUtils';
import shiftPaymentsApi, { type PaymentMethod } from '../../../api/shiftPaymentsApi';
import fetchApi from '../../../api/fetchiApi';
import { traceInfo } from '../../../lib/telemetryUtils';
import { allowJoinBrokerNetwork } from '../OldAddInvoice/actions';

export const {
  getInvoicePending,
  getInvoiceFulfilled,
  getInvoiceRejected,
  getTermsFeesAndPaymentTypesPending,
  getTermsFeesAndPaymentTypesFulfilled,
  getTermsFeesAndPaymentTypesRejected,
  getTransactionFeesPending,
  getTransactionFeesFulfilled,
  getTransactionFeesRejected,
  getExtensionTermsAndFeesPending,
  getExtensionTermsAndFeesFulfilled,
  getExtensionTermsAndFeesRejected,
  confirmInvoicePending,
  confirmInvoiceFulfilled,
  confirmInvoiceRejected,
  declineInvoicePending,
  declineInvoiceFulfilled,
  declineInvoiceRejected,
  addInvoicePending,
  addInvoiceFulfilled,
  addInvoiceRejected,
  getSupplierBankAccountPending,
  getSupplierBankAccountFulfilled,
  getSupplierBankAccountRejected,
  setPaymentDate,
  setInstallmentTermInWeeks,
  getInvoiceAttachmentPending,
  getInvoiceAttachmentFulfilled,
  getInvoiceAttachmentRejected,
  setSupplierAuthorizationPending,
  setSupplierAuthorizationFulfilled,
  setSupplierAuthorizationRejected,
  setInvoiceData,
  getStaffPageVisibilityPending,
  getStaffPageVisibilityFulfilled,
  getStaffPageVisibilityRejected,
  addSupplierPending,
  addSupplierFulfilled,
  addSupplierRejected,
  editSupplierFulfilled,
  editSupplierRejected,
} = createActions(
  actionTypes.GET_INVOICE_PENDING,
  actionTypes.GET_INVOICE_FULFILLED,
  actionTypes.GET_INVOICE_REJECTED,
  actionTypes.GET_TERMS_FEES_AND_PAYMENT_TYPES_PENDING,
  actionTypes.GET_TERMS_FEES_AND_PAYMENT_TYPES_FULFILLED,
  actionTypes.GET_TERMS_FEES_AND_PAYMENT_TYPES_REJECTED,
  actionTypes.GET_TRANSACTION_FEES_PENDING,
  actionTypes.GET_TRANSACTION_FEES_FULFILLED,
  actionTypes.GET_TRANSACTION_FEES_REJECTED,
  actionTypes.GET_EXTENSION_TERMS_AND_FEES_PENDING,
  actionTypes.GET_EXTENSION_TERMS_AND_FEES_FULFILLED,
  actionTypes.GET_EXTENSION_TERMS_AND_FEES_REJECTED,
  actionTypes.CONFIRM_INVOICE_PENDING,
  actionTypes.CONFIRM_INVOICE_FULFILLED,
  actionTypes.CONFIRM_INVOICE_REJECTED,
  actionTypes.DECLINE_INVOICE_PENDING,
  actionTypes.DECLINE_INVOICE_FULFILLED,
  actionTypes.DECLINE_INVOICE_REJECTED,
  actionTypes.ADD_INVOICE_PENDING,
  actionTypes.ADD_INVOICE_FULFILLED,
  actionTypes.ADD_INVOICE_REJECTED,
  actionTypes.GET_SUPPLIER_BANK_ACCOUNT_PENDING,
  actionTypes.GET_SUPPLIER_BANK_ACCOUNT_FULFILLED,
  actionTypes.GET_SUPPLIER_BANK_ACCOUNT_REJECTED,
  actionTypes.SET_PAYMENT_DATE,
  actionTypes.SET_INSTALLMENT_TERM_IN_WEEKS,
  actionTypes.GET_INVOICE_ATTACHMENT_PENDING,
  actionTypes.GET_INVOICE_ATTACHMENT_FULFILLED,
  actionTypes.GET_INVOICE_ATTACHMENT_REJECTED,
  actionTypes.SET_SUPPLIER_AUTHORIZATION_PENDING,
  actionTypes.SET_SUPPLIER_AUTHORIZATION_FULFILLED,
  actionTypes.SET_SUPPLIER_AUTHORIZATION_REJECTED,
  actionTypes.SET_INVOICE_DATA,
  actionTypes.GET_STAFF_PAGE_VISIBILITY_PENDING,
  actionTypes.GET_STAFF_PAGE_VISIBILITY_FULFILLED,
  actionTypes.GET_STAFF_PAGE_VISIBILITY_REJECTED,
  actionTypes.ADD_SUPPLIER_PENDING,
  actionTypes.ADD_SUPPLIER_FULFILLED,
  actionTypes.ADD_SUPPLIER_REJECTED,
  actionTypes.EDIT_SUPPLIER_FULFILLED,
  actionTypes.EDIT_SUPPLIER_REJECTED,
  getActionOptions(ACTION_PREFIX)
);

const getInvoice = (id: string, gcAccountId: string) => (dispatch: Dispatch<any>) => {
  dispatch(getInvoicePending());

  return shiftPaymentsApi
    .getInvoice(id, gcAccountId)
    .then((response) => {
      return dispatch(getInvoiceFulfilled(response));
    })
    .catch((error) => {
      return dispatch(getInvoiceRejected(error));
    });
};

const addNewInvoice = (
  buyerGcAccountId: string,
  invoiceNumber: string,
  amount: number,
  invoiceDescription: string,
  fileGroupId: string,
  attachments: Array<string>,
  supplierGcAccountId: string,
  paymentMethod: PaymentMethod,
  isNewSupplier: boolean,
  isDirector: boolean,
  invoiceConfirmRequestId: string,
  paymentDate?: Date,
  installmentTermInWeeks?: number,
  dispatch: Dispatch<any>
) => {
  dispatch(getInvoicePending());
  window.dispatchEvent(new Event('fetchStart'));

  return fetchApi
    .addInvoice(
      buyerGcAccountId,
      invoiceNumber,
      amount,
      invoiceDescription,
      fileGroupId,
      attachments,
      supplierGcAccountId,
      paymentMethod,
      isNewSupplier,
      isDirector,
      invoiceConfirmRequestId,
      paymentDate,
      installmentTermInWeeks
    )
    .then((response) => response.json())
    .then((addInvoiceResponse) => {
      return dispatch(addInvoiceFulfilled(addInvoiceResponse));
    })
    .catch((error) => {
      return dispatch(addInvoiceRejected(error));
    })
    .finally(() => {
      window.dispatchEvent(new Event('fetchEnd'));
    });
};

const addScheduledInvoice =
  (
    buyerGcAccountId: string,
    invoiceNumber: string,
    amount: number,
    invoiceDescription: string,
    fileGroupId: string,
    attachments: Array<any>,
    paymentMethod: PaymentMethod,
    paymentDate: ?Date,
    installmentTermInWeeks: ?number,
    isNewSupplier: boolean,
    isEditSupplier: boolean,
    disbursalDate: ?Date,
    supplierGcAccountId: string,
    supplierAbn: string,
    supplierBsb: string,
    supplierBankAccountNumber: string,
    isDirector: boolean,
    invoiceConfirmRequestId: string
  ) =>
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  async (dispatch: Dispatch<any>) => {
    // eslint-disable-next-line no-console
    console.log(
      `PLACEHOLDER::scheduled add invoice: ${JSON.stringify(
        {buyerGcAccountId,
        invoiceNumber,
        amount,
        invoiceDescription,
        fileGroupId,
        attachments,
        paymentMethod,
        paymentDate,
        installmentTermInWeeks,
        isNewSupplier,
        isEditSupplier,
        disbursalDate,
        supplierGcAccountId,
        supplierAbn,
        supplierBsb,
        supplierBankAccountNumber,
        isDirector,
        invoiceConfirmRequestId,
      })
      }`
    );
    // const supplierResponse = await shiftPaymentsApi.getPublicSuppliers(buyerGcAccountId, supplierAbn, 0, 1, []);

    // if (!supplierResponse.data.totalCount) {
    //   dispatch(addSupplierPending());
    //   return shiftPaymentsApi
    //     .addSupplier(buyerGcAccountId, supplierAbn, '', false, supplierBsb, supplierBankAccountNumber)
    //     .then((addSupplierResponse) => {
    //       traceInfo('2 - Handling reponse for addSupplier - UTC time is:' + new Date().toISOString());
    //       dispatch(addSupplierFulfilled(addSupplierResponse));
    //       dispatch(addInvoicePending());

    //       const invoiceFileNames = attachments.map((file) => file.name);

    //       return addNewInvoice(
    //         buyerGcAccountId,
    //         invoiceNumber,
    //         amount,
    //         invoiceDescription,
    //         fileGroupId,
    //         invoiceFileNames,
    //         supplierGcAccountId || addSupplierResponse.data.gcAccountId,
    //         paymentMethod,
    //         isNewSupplier,
    //         isEditSupplier,
    //         disbursalDate,
    //         isDirector,
    //         invoiceConfirmRequestId,
    //         paymentDate,
    //         installmentTermInWeeks,
    //         dispatch
    //       );
    //     })
    //     .catch((error) => {
    //       traceInfo('2 - Handling reponse error for addSupplier - UTC time is:' + new Date().toISOString());
    //       return dispatch(addSupplierRejected(error));
    //     });
    // }
    // const supplierGcAccountIdToUse = supplierResponse.data.items.find((x) => x.abn === supplierAbn).accountId;

    // dispatch(addInvoicePending());
    // const invoiceFileNames = attachments.map((file) => file.name);

    // return addNewInvoice(
    //   buyerGcAccountId,
    //   invoiceNumber,
    //   amount,
    //   invoiceDescription,
    //   fileGroupId,
    //   invoiceFileNames,
    //   supplierGcAccountId || supplierGcAccountIdToUse,
    //   paymentMethod,
    //   isNewSupplier,
    //   isEditSupplier,
    //   isDirector,
    //   invoiceConfirmRequestId,
    //   paymentDate,
    //   installmentTermInWeeks,
    //   dispatch
    // );
  };

const addInvoice =
  (
    buyerGcAccountId: string,
    invoiceNumber: string,
    amount: number,
    invoiceDescription: string,
    fileGroupId: string,
    attachments: Array<any>,
    paymentMethod: PaymentMethod,
    paymentDate: ?Date,
    installmentTermInWeeks: ?number,
    isNewSupplier: boolean,
    supplierGcAccountId: string,
    supplierAbn: string,
    supplierBsb: string,
    supplierBankAccountNumber: string,
    isDirector: boolean,
    invoiceConfirmRequestId: string
  ) =>
  async (dispatch: Dispatch<any>) => {
    const supplierResponse = await shiftPaymentsApi.getPublicSuppliers(buyerGcAccountId, supplierAbn, 0, 1, []);

    if (!supplierResponse.data.totalCount) {
      dispatch(addSupplierPending());
      return shiftPaymentsApi
        .addSupplier(buyerGcAccountId, supplierAbn, '', false, supplierBsb, supplierBankAccountNumber)
        .then((addSupplierResponse) => {
          traceInfo('2 - Handling reponse for addSupplier - UTC time is:' + new Date().toISOString());
          dispatch(addSupplierFulfilled(addSupplierResponse));
          dispatch(addInvoicePending());

          const invoiceFileNames = attachments.map((file) => file.name);

          return addNewInvoice(
            buyerGcAccountId,
            invoiceNumber,
            amount,
            invoiceDescription,
            fileGroupId,
            invoiceFileNames,
            supplierGcAccountId || addSupplierResponse.data.gcAccountId,
            paymentMethod,
            isNewSupplier,
            isDirector,
            invoiceConfirmRequestId,
            paymentDate,
            installmentTermInWeeks,
            dispatch
          );
        })
        .catch((error) => {
          traceInfo('2 - Handling reponse error for addSupplier - UTC time is:' + new Date().toISOString());
          return dispatch(addSupplierRejected(error));
        });
    }
    const supplierGcAccountIdToUse = supplierResponse.data.items.find((x) => x.abn === supplierAbn).accountId;

    dispatch(addInvoicePending());
    const invoiceFileNames = attachments.map((file) => file.name);

    return addNewInvoice(
      buyerGcAccountId,
      invoiceNumber,
      amount,
      invoiceDescription,
      fileGroupId,
      invoiceFileNames,
      supplierGcAccountId || supplierGcAccountIdToUse,
      paymentMethod,
      isNewSupplier,
      isDirector,
      invoiceConfirmRequestId,
      paymentDate,
      installmentTermInWeeks,
      dispatch
    );
  };
  

const getSupplierBankAccount = (buyerGcAccountId: string, supplierGcAccountId: string) => (dispatch: Dispatch<any>) => {
  dispatch(getSupplierBankAccountPending());

  return shiftPaymentsApi
    .getSupplierBankAccount(buyerGcAccountId, supplierGcAccountId)
    .then((response) => {
      return dispatch(getSupplierBankAccountFulfilled(response));
    })
    .catch((error) => {
      return dispatch(getSupplierBankAccountRejected(error));
    });
};

const editSupplierAndAddInvoice =
  (
    buyerGcAccountId: string,
    invoiceNumber: string,
    amount: number,
    invoiceDescription: string,
    fileGroupId: string,
    attachments: Array<any>,
    paymentMethod: PaymentMethod,
    paymentDate: ?Date,
    installmentTermInWeeks: ?number,
    isNewSupplier: boolean,
    supplierGcAccountId: string,
    supplierAbn: string,
    supplierBsb: string,
    supplierBankAccountNumber: string,
    isDirector: boolean,
    invoiceConfirmRequestId: string
  ) =>
  async (dispatch: Dispatch<any>) => {
    //this request flow is orginated from edit supplier page
    return shiftPaymentsApi
      .editSupplierBankAccount(buyerGcAccountId, supplierAbn, supplierBsb, supplierBankAccountNumber)
      .then((response) => {
        dispatch(editSupplierFulfilled(response));
        dispatch(addInvoicePending());
        const invoiceFileNames = attachments.map((file) => file.name);

        return addNewInvoice(
          buyerGcAccountId,
          invoiceNumber,
          amount,
          invoiceDescription,
          fileGroupId,
          invoiceFileNames,
          supplierGcAccountId,
          paymentMethod,
          isNewSupplier,
          isDirector,
          invoiceConfirmRequestId,
          paymentDate,
          installmentTermInWeeks,
          dispatch
        );
      })
      .catch((error) => {
        return dispatch(editSupplierRejected(error));
      });
  };

const confirmInvoice =
  (contractId: string, gcAccountId: string, paymentMethod: PaymentMethod, requestId: string, selectedDate?: Date, selectedTerm?: number) =>
  (dispatch: Dispatch<any>) => {
    dispatch(confirmInvoicePending());

    window.dispatchEvent(new Event('fetchStart'));
    return fetchApi
      .confirmInvoice(contractId, gcAccountId, paymentMethod, requestId, selectedDate, selectedTerm)
      .then((response) => {
        if (!response.ok && response.status !== 400) {
          return response;
        }
        return response.json();
      })
      .then((response) => dispatch(confirmInvoiceFulfilled(response)))
      .catch((error) => {
        return dispatch(confirmInvoiceRejected(error));
      })
      .finally(() => {
        window.dispatchEvent(new Event('fetchEnd'));
      });
  };

const declineInvoice = (contractId: string, gcAccountId: string) => (dispatch: Dispatch<any>) => {
  dispatch(declineInvoicePending());

  window.dispatchEvent(new Event('fetchStart'));
  return fetchApi
    .declineInvoice(contractId, gcAccountId)
    .then((response) => response.json())
    .then((response) => {
      return dispatch(declineInvoiceFulfilled(response));
    })
    .catch((error) => {
      return dispatch(declineInvoiceRejected(error));
    })
    .finally(() => {
      window.dispatchEvent(new Event('fetchEnd'));
    });
};

const getTermsFeesAndPaymentTypes = (supplierGcAccountId: string, buyerGcAccountId: string, amount: number) => (dispatch: Dispatch<any>) => {
  dispatch(getTermsFeesAndPaymentTypesPending());

  return shiftPaymentsApi
    .getTermsFeesAndPaymentTypes(supplierGcAccountId, buyerGcAccountId, amount)
    .then((response) => {
      return dispatch(getTermsFeesAndPaymentTypesFulfilled(response));
    })
    .catch((error) => {
      return dispatch(getTermsFeesAndPaymentTypesRejected(error));
    });
};

const getTransactionFees = (supplierGcAccountId: string, buyerGcAccountId: string, amount: number, scheduledDate: ?Date) => (dispatch: Dispatch<any>) => {
  dispatch(getTransactionFeesPending());

  return shiftPaymentsApi
    .getTransactionFees(supplierGcAccountId, buyerGcAccountId, amount, scheduledDate)
    .then((response) => {
      return dispatch(getTransactionFeesFulfilled(response));
    })
    .catch((error) => {
      return dispatch(getTransactionFeesRejected(error));
    });
};

const getExtensionTermsAndFees = (invoiceId: string, supplierGcAccountId: string, buyerGcAccountId: string) => (dispatch: Dispatch<any>) => {
  dispatch(getExtensionTermsAndFeesPending());

  return shiftPaymentsApi
    .getInvoiceExtensionTermsAndFees(invoiceId, supplierGcAccountId, buyerGcAccountId)
    .then((response) => {
      return dispatch(getExtensionTermsAndFeesFulfilled(response));
    })
    .catch((error) => {
      return dispatch(getExtensionTermsAndFeesRejected(error));
    });
};

const getInvoiceAttachment = (buyerGcAccountId: string, filePath: string) => (dispatch: Dispatch<any>) => {
  dispatch(getInvoiceAttachmentPending());

  return shiftPaymentsApi
    .getInvoiceAttachment(buyerGcAccountId, filePath)
    .then((response) => {
      // HACK: https://gist.github.com/javilobo8/097c30a233786be52070986d8cdb1743
      const url = window.URL.createObjectURL(new Blob([response.data], { type: response.headers['content-type'] }));
      const link = document.createElement('a');
      link.id = buyerGcAccountId;
      link.href = url;
      const lastSlashIndex = filePath.lastIndexOf('/');
      link.setAttribute('download', lastSlashIndex > -1 ? filePath.substring(lastSlashIndex + 1) : filePath);
      // $FlowFixMe
      document.body.appendChild(link);
      link.click();
    })
    .then((response) => {
      // $FlowFixMe
      document.getElementById(buyerGcAccountId).remove();
      return dispatch(getInvoiceAttachmentFulfilled(response));
    })
    .catch((error) => {
      return dispatch(getInvoiceAttachmentRejected(error));
    });
};

const setSupplierAuthorization =
  (buyerGcAccountId: string, supplierGcAccountId: string, authorizationStatus: AuthorizationStatus, reason: ?string) => (dispatch: Dispatch<any>) => {
    dispatch(setSupplierAuthorizationPending());

    return shiftPaymentsApi
      .setSupplierAuthorization(buyerGcAccountId, supplierGcAccountId, authorizationStatus, reason)
      .then((response) => {
        return dispatch(setSupplierAuthorizationFulfilled(response));
      })
      .catch((error) => {
        return dispatch(setSupplierAuthorizationRejected(error));
      });
  };

const resetInvoice = () => (dispatch: Dispatch<any>) => {
  return dispatch(setInvoiceData({}));
};

const getStaffPageVisibility = (gcAccountId: string) => (dispatch: Dispatch<any>) => {
  dispatch(getStaffPageVisibilityPending());

  return shiftPaymentsApi
    .getStaffPageVisibility(gcAccountId)
    .then((response) => {
      return dispatch(getStaffPageVisibilityFulfilled(response));
    })
    .catch((error) => {
      return dispatch(getStaffPageVisibilityRejected(error));
    });
};

export default {
  getInvoice,
  getTermsFeesAndPaymentTypes,
  getTransactionFees,
  confirmInvoice,
  declineInvoice,
  addScheduledInvoice,
  addInvoice,
  getSupplierBankAccount,
  editSupplierAndAddInvoice,
  setPaymentDate,
  setInstallmentTermInWeeks,
  getInvoiceAttachment,
  setSupplierAuthorization,
  resetInvoice,
  getStaffPageVisibility,
  allowJoinBrokerNetwork,
  getExtensionTermsAndFees,
};
