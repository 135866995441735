import { makeStyles } from '@material-ui/core';
import CalendarTodayIcon from '@material-ui/icons/CalendarToday';
import { DatePicker } from '@material-ui/pickers';
import { MaterialUiPickersDate } from '@material-ui/pickers/typings/date';

const useStyles = makeStyles((theme) => ({
  datePicker: {
    '& .MuiPickersDay-daySelected': {
      fontWeight: '500 !important',
    },
    '& .MuiInputBase-input': {
      cursor: 'pointer',
      paddingBottom: '14.5px',
    },
    '& .MuiSvgIcon-root': {
      color: theme.palette.primary.light,
      cursor: 'pointer',
      marginTop: '-9.5px',
    },
    '& .MuiInput-underline:before': {
      border: '0px !important',
    },
    '& .MuiInput-underline:after': {
      border: '0px !important',
    },
    '& .MuiInputBase-root': {
      border: `1px solid ${theme.palette.grey['300']}`,
      borderRadius: '4px',
      padding: '9px 14px 0px 14px',
    },
    '& .MuiInputBase-root:hover': {
      border: `1px solid ${theme.palette.primary.light}`,
    },
    '&': {
      width: '100%',
    },
  },
}));

type Props = {
  minDate: moment.Moment;
  maxDate: moment.Moment;
  selectedDate?: moment.Moment;
  shouldDisableDate: (day?: MaterialUiPickersDate) => boolean;
  onDateChange: (day: MaterialUiPickersDate) => void;
};

const Datepicker = (props: Props) => {
  const { selectedDate, minDate, maxDate, shouldDisableDate, onDateChange } = props;
  const classes = useStyles();

  return (
    <DatePicker
      className={classes.datePicker}
      disableToolbar
      autoOk
      variant='inline'
      format='ddd, D MMM yyyy'
      InputProps={{ endAdornment: <CalendarTodayIcon /> }}
      openTo='date'
      value={selectedDate}
      minDate={minDate}
      maxDate={maxDate}
      shouldDisableDate={shouldDisableDate}
      onChange={onDateChange}
    />
  );
};

export default Datepicker;
