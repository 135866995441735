// @flow
import React from 'react';
import { Route, Redirect, Switch } from 'react-router';

import { ConditionalRoute } from '../../components/ConditionalRoute';
import Layout from '../../components/Layout';
import { AccountSetupCheck } from '../../components/AccountSetupCheck';
import { InitialData } from '../../components/InitialData';
import { WelcomePage } from '../Welcome';
import { AddBank } from '../AddBank';
import { AddBankLogin } from '../AddBankLogin';
import { ManualBankStatement } from '../ManualBankStatements';
import { AddBankSelectAccount } from '../AddBankSelectAccount';
import { LoanAgreement } from '../LoanAgreement';
import { PrivacyAgreement } from '../PrivacyAgreement';
import { Settings } from '../Settings';
import { Dashboard as BuyerDashboard } from '../Buyer/Dashboard';

import { Invoices } from '../Buyer/Invoices';
import { OldAddInvoice } from '../Buyer/OldAddInvoice';
import { AddInvoice } from '../Buyer/AddInvoice';
import { EditSupplier } from '../Buyer/EditSupplier';
import { Suppliers } from '../Buyer/Suppliers';
import { SupplierRequest } from '../Buyer/SupplierRequest';
import { Review } from '../Buyer/Review';
import { PaymentSchedule } from '../Buyer/PaymentSchedule';
import { ShareInvoice } from '../Buyer/ShareInvoice';
import { TrustDeed } from '../Buyer/TrustDeed';
import { SupplierInvoiceReviewAuthorization } from '../Buyer/SupplierInvoiceReviewAuthorization';
import { AddAPMStaffs } from '../Buyer/AddAPMStaffs';
import { Resources } from '../Supplier/Resources';
import { AccountOpeningWidget } from '../Supplier/AccountOpeningWidget';
import { TransactionAPIWidget } from '../Supplier/TransactionAPIWidget';

import { Customers } from '../Supplier/Customers';
import { Payments } from '../Supplier/Payments';
import { AddPayment } from '../Supplier/AddPayment';
import { AddCustomer } from '../Supplier/AddCustomer';
import { RequestSupplierAccess } from '../Supplier/RequestSupplierAccess';
import { GlobalSessionHandler } from '../../components/GlobalSessionHandler';
import { AbilityProvider } from '../../components/AbilityProvider';
import { Permission } from '../../constants';
import { DirectDebitAgreement } from '../DirectDebitAgreement';
import { PayNewOrExistingSupplier } from '../Buyer/PayNewOrExistingSupplier';
import { ExtendInvoice } from '../Buyer/ExtendInvoice';
import { ExtendInvoiceConfirmation } from '../Buyer/ExtendInvoice/Confirmation';
import Main from '../Main/Component';
import { useFlags } from '../../lib/feature-flags';
import InvoicesContainer from '../Buyer/InvoicesContainer/InvoicesContainer';

type Props = {
  isSupplier: boolean,
  isAllKycCompleted: boolean,
  activeAccountTrustDeedNeedsApproval: boolean,
  isAllSetupCompleteRequirement: boolean,
  isTermsAndConditionsAgreementSetupComplete: boolean,
  isAccountReady: boolean,
};

const PrivateRoutes = ({
  isSupplier,
  isAllKycCompleted,
  activeAccountTrustDeedNeedsApproval,
  isAllSetupCompleteRequirement,
  isTermsAndConditionsAgreementSetupComplete,
  isAccountReady,
}: Props) => {
  const [localIsAllKycCompleted, setLocalIsAllCompleted] = React.useState(isAllKycCompleted);
  const [localIsAllSetupCompleteRequirement, setLocalIsAllSetupCompleteRequirement] = React.useState(isAllSetupCompleteRequirement);
  const [localIsSupplier, setLocalIsSupplier] = React.useState(isSupplier);
  const [localIsAccountReady, setLocalIsAccountReady] = React.useState(isAccountReady);
  const [localIsTermsAndConditionsAgreementSetupComplete, setLocalIsTermsAndConditionsAgreementSetupComplete] = React.useState(
    isTermsAndConditionsAgreementSetupComplete
  );
  const flags = useFlags();

  React.useEffect(() => {
    setLocalIsAllCompleted(isAllKycCompleted);
    setLocalIsAllSetupCompleteRequirement(isAllSetupCompleteRequirement);
    setLocalIsSupplier(isSupplier);
    setLocalIsAccountReady(isAccountReady);
    setLocalIsTermsAndConditionsAgreementSetupComplete(isTermsAndConditionsAgreementSetupComplete);
  }, [isAllKycCompleted, isAllSetupCompleteRequirement, isSupplier, isAccountReady, isTermsAndConditionsAgreementSetupComplete]);

  const requireBuyerProfile = localIsAllKycCompleted && localIsAllSetupCompleteRequirement;
  const requireSupplierProfile = localIsSupplier;
  const requireApprovedTrustDeed = requireBuyerProfile && localIsAccountReady;
  const scheduledPaymentsFlag = flags.scheduledPayments;

  return (
    <>
      <InitialData />
      <Switch>
        <ConditionalRoute condition={requireApprovedTrustDeed} exact path='/buyer/payments/paid' component={ShareInvoice} />
        <ConditionalRoute condition={requireApprovedTrustDeed} exact path='/buyer/payments/:id/extended' component={ExtendInvoiceConfirmation} />
        <Route path='/'>
          <AbilityProvider>
            {(ability) => (
              <>
                <AccountSetupCheck />
                <GlobalSessionHandler />
                <Layout>
                  <Route exact path='/welcome' component={WelcomePage} />
                  {localIsTermsAndConditionsAgreementSetupComplete ? (
                    <Switch>
                      <Route exact path='/p' component={Main} />
                      <ConditionalRoute
                        condition={localIsAllSetupCompleteRequirement || !ability.can(Permission.Actions.Create, Permission.Buyer.BankAccount)}
                        exact
                        path='/buyer/dashboard'
                        component={BuyerDashboard}
                      />
                      <Route exact path='/'>
                        <Redirect to='/p' />
                      </Route>
                      <ConditionalRoute
                        condition={requireBuyerProfile}
                        exact
                        path='/buyer/payments'
                        component={scheduledPaymentsFlag ? InvoicesContainer : Invoices}
                      />
                      <ConditionalRoute
                        condition={requireApprovedTrustDeed ? ability.can(Permission.Actions.Create, Permission.Buyer.Invoice) : null}
                        exact
                        path='/buyer/payments/pay-new-or-existing'
                        component={PayNewOrExistingSupplier}
                      />
                      <ConditionalRoute
                        condition={requireApprovedTrustDeed ? ability.can(Permission.Actions.Create, Permission.Buyer.Invoice) : null}
                        exact
                        path='/buyer/payments/pay/:supplierGcAccountId?'
                        component={scheduledPaymentsFlag ? AddInvoice : OldAddInvoice}
                      />
                      <ConditionalRoute
                        condition={requireApprovedTrustDeed ? ability.can(Permission.Actions.Create, Permission.Buyer.Invoice) : null}
                        exact
                        path='/buyer/supplier/edit:supplierGcAccountId?'
                        component={EditSupplier}
                      />
                      <ConditionalRoute condition={requireBuyerProfile} exact path='/buyer/suppliers' component={Suppliers} />
                      <ConditionalRoute
                        condition={requireApprovedTrustDeed}
                        exact
                        path='/buyer/requests/:supplierGcAccountId'
                        component={SupplierRequest}
                      />
                      <ConditionalRoute condition={requireBuyerProfile} exact path='/buyer/payment-schedule' component={PaymentSchedule} />
                      <ConditionalRoute
                        condition={requireApprovedTrustDeed ? ability.can(Permission.Actions.Create, Permission.Buyer.Invoice) : null}
                        exact
                        path={['/buyer/payments/:id/review', '/buyer/payments/review']}
                        component={Review}
                      />
                      <ConditionalRoute
                        condition={requireApprovedTrustDeed ? ability.can(Permission.Actions.Create, Permission.Buyer.Invoice) : null}
                        exact
                        path='/buyer/payments/:id/extend'
                        component={ExtendInvoice}
                      />
                      <ConditionalRoute
                        condition={requireApprovedTrustDeed ? ability.can(Permission.Actions.Create, Permission.Buyer.Invoice) : null}
                        exact
                        path='/buyer/payments/review-authorization'
                        component={SupplierInvoiceReviewAuthorization}
                      />
                      <ConditionalRoute
                        condition={ability.can(Permission.Actions.Create, Permission.Buyer.Invoice)}
                        exact
                        path='/buyer/payments/staffs/add'
                        component={AddAPMStaffs}
                      />
                      <ConditionalRoute
                        condition={localIsAllSetupCompleteRequirement ? activeAccountTrustDeedNeedsApproval : null}
                        exact
                        path='/buyer/trust-deed'
                        component={TrustDeed}
                      />
                      <ConditionalRoute
                        condition={requireBuyerProfile}
                        exact
                        path='/supplier/request-supplier-access'
                        component={RequestSupplierAccess}
                      />
                      <ConditionalRoute condition={requireBuyerProfile} exact path='*/settings' component={Settings} />
                      <Route exact path='*/settings'>
                        <Redirect to='/settings' />
                      </Route>
                      <ConditionalRoute
                        condition={ability.can(Permission.Actions.Create, Permission.Buyer.BankAccount)}
                        exact
                        path='*/settings/add-bank'
                        component={AddBank}
                      />
                      <ConditionalRoute
                        condition={ability.can(Permission.Actions.Create, Permission.Buyer.BankAccount)}
                        exact
                        path='*/settings/add-bank/login/:bankId'
                        component={AddBankLogin}
                      />
                      <ConditionalRoute
                        condition={ability.can(Permission.Actions.Create, Permission.Buyer.BankAccount)}
                        exact
                        path='*/settings/add-bank/manual-statement/:bankId'
                        component={ManualBankStatement}
                      />
                      <ConditionalRoute
                        condition={ability.can(Permission.Actions.Create, Permission.Buyer.BankAccount)}
                        exact
                        path='*/settings/add-bank/account-select/:bankId?/:lbfcId?/:mfa?'
                        component={AddBankSelectAccount}
                      />
                      <ConditionalRoute
                        condition={ability.can(Permission.Actions.Sign, Permission.Buyer.PrivacyAgreement)}
                        exact
                        path='/privacy-agreement'
                        component={PrivacyAgreement}
                      />
                      <ConditionalRoute
                        condition={ability.can(Permission.Actions.Sign, Permission.Buyer.LoanAgreement)}
                        exact
                        path='/loan-agreement'
                        component={LoanAgreement}
                      />
                      <ConditionalRoute
                        condition={ability.can(Permission.Actions.Sign, Permission.Buyer.DDRAgreement)}
                        exact
                        path='/direct-debit-agreement'
                        component={DirectDebitAgreement}
                      />
                    </Switch>
                  ) : null}
                  <Switch>
                    <ConditionalRoute
                      condition={ability.can(Permission.Actions.Create, Permission.Supplier.Buyer)}
                      exact
                      path='/supplier/resources'
                      component={Resources}
                    />
                    <ConditionalRoute
                      condition={ability.can(Permission.Actions.Create, Permission.Supplier.Buyer)}
                      exact
                      path='/supplier/resources/widgets/account-opening'
                      component={AccountOpeningWidget}
                    />
                    <ConditionalRoute condition={requireSupplierProfile} exact path='/supplier/customers' component={Customers} />
                    <ConditionalRoute
                      condition={requireSupplierProfile ? ability.can(Permission.Actions.Create, Permission.Supplier.Buyer) : null}
                      exact
                      path='/supplier/customers/add'
                      component={AddCustomer}
                    />
                    <ConditionalRoute condition={requireSupplierProfile} exact path='/supplier/payments' component={Payments} />
                    <ConditionalRoute
                      condition={requireSupplierProfile ? ability.can(Permission.Actions.Create, Permission.Supplier.Payment) : null}
                      exact
                      path='/supplier/payments/add/:buyerGcAccountId?'
                      component={AddPayment}
                    />
                    <ConditionalRoute
                      condition={ability.can(Permission.Actions.Create, Permission.Supplier.Buyer)}
                      exact
                      path='/supplier/resources/widgets/transaction-api'
                      component={TransactionAPIWidget}
                    />
                  </Switch>
                </Layout>
              </>
            )}
          </AbilityProvider>
        </Route>
        <Route exact path='*/message'>
          <Redirect to='/message' />
        </Route>
        <Route path='*'>
          <Redirect to='/404' />
        </Route>
      </Switch>
    </>
  );
};

export default PrivateRoutes;
