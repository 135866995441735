import {DisbursalMethod} from './types';
// @flow
export const ACTION_PREFIX = 'ADD_INVOICE';
export const PARTITION = 'addInvoice';

export const actionTypes = {
  GET_ACCOUNT_BALANCE_PENDING: 'GET_ACCOUNT_BALANCE_PENDING',
  GET_ACCOUNT_BALANCE_FULFILLED: 'GET_ACCOUNT_BALANCE_FULFILLED',
  GET_ACCOUNT_BALANCE_REJECTED: 'GET_ACCOUNT_BALANCE_REJECTED',
  ADD_INVOICE_PENDING: 'ADD_INVOICE_PENDING',
  ADD_INVOICE_FULFILLED: 'ADD_INVOICE_FULFILLED',
  GET_SUPPLIER_BANK_ACCOUNT_PENDING: 'GET_SUPPLIER_BANK_ACCOUNT_PENDING',
  GET_SUPPLIER_BANK_ACCOUNT_FULFILLED: 'GET_SUPPLIER_BANK_ACCOUNT_FULFILLED',
  GET_SUPPLIER_BANK_ACCOUNT_REJECTED: 'GET_SUPPLIER_BANK_ACCOUNT_REJECTED',
  CHECK_BSB_PENDING: 'CHECK_BSB_PENDING',
  CHECK_BSB_FULFILLED: 'CHECK_BSB_FULFILLED',
  CHECK_BSB_REJECTED: 'CHECK_BSB_REJECTED',
  GET_SUPPLIER_PENDING: 'GET_SUPPLIER_PENDING',
  GET_SUPPLIER_FULFILLED: 'GET_SUPPLIER_FULFILLED',
  GET_SUPPLIER_REJECTED: 'GET_SUPPLIER_REJECTED',
  FILE_DELETE_PENDING: 'FILE_DELETE_PENDING',
  FILE_DELETE_FULFILLED: 'FILE_DELETE_FULFILLED',
  FILE_DELETE_REJECTED: 'FILE_DELETE_REJECTED',
  FILE_UPLOAD_PENDING: 'FILE_UPLOAD_PENDING',
  FILE_UPLOAD_FULFILLED: 'FILE_UPLOAD_FULFILLED',
  FILE_UPLOAD_REJECTED: 'FILE_UPLOAD_REJECTED',
  SET_ALLOW_JOIN_BROKER_NETWORK: 'SET_ALLOW_JOIN_BROKER_NETWORK',
};

export const SupplierSelectionMode = {
  None: 'None',
  Existing: 'Existing',
  New: 'New',
};

export type SupplierSelectionModeEnum = $Keys<typeof SupplierSelectionMode>;

export const NowDisbursalMethod: DisbursalMethod = {
  label: 'Now', value: 'now' 
}
export const LaterDisbursalMethod: DisbursalMethod = {
  label: 'Later', value: 'later' 
}

export const DisbursalMethods: DisbursalMethod[] = [
  NowDisbursalMethod,
  LaterDisbursalMethod,
];